import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PageLoading from '@/components/PageLoading';
import NavHeader from '@/components/NavHeaderHome';

import { findManySessions } from '@/utils/TrainerSessions';
import { findManyClasses } from "@/utils/Classes";

import { Select, Stack, MenuItem, FormControl, InputLabel } from '@mui/material';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';

import dayjs from 'dayjs';
import DayView from './Components/DayView';
import WeekView from './Components/WeekView';
import { useSelector } from 'react-redux';
import { findManyTrainerSchedules } from '@/utils/TrainerSchedule';
import { useTranslation } from 'react-i18next';

// Extend dayjs with plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const headCells = [
    {
        id: 'class',
        numeric: false,
        disablePadding: true,
        label: 'Class',
    },
    {
        id: 'capacity',
        numeric: true,
        disablePadding: false,
        label: 'Capacity',
    },
    {
        id: 'users',
        numeric: true,
        disablePadding: false,
        label: 'Users',
    },
    {
        id: 'start_time',
        numeric: true,
        disablePadding: false,
        label: 'Start',
    },
    {
        id: 'end_time',
        numeric: true,
        disablePadding: false,
        label: 'End',
    },
];

export default function EnhancedTable() {

    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [step, setStep] = useState(0);
    const [view, setView] = useState('day');
    const [isLoading, setIsLoading] = useState(false);
    const { token, user, status } = useSelector((state) => state.auth);
    const [classes, setClasses] = useState(false);
    const [calendar, setCalendar] = useState(false);

    const ViewComponent = {
        day: <DayView data={data} step={step} calendar={calendar} />,
        week: <WeekView data={data} step={step} calendar={calendar} />,
    }

    const [filter, setFilter] = useState({
        trainer_id: null,
        class_id: null,
        start_time: null,
        end_time: null
    });

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter])

    useEffect(() => {
        const start_time = dayjs().add(step, view).startOf(view).format('YYYY-MM-DD HH:mm:ss');
        const end_time = dayjs().add(step, view).endOf(view).format('YYYY-MM-DD HH:mm:ss');
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step, view])

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManySessions(token, params);
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    async function handleFindManyClasses() {
        try {
            const response = await findManyClasses({});
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setClasses(data);
                }
            }
        } catch (error) {

        }
    }

    async function handleFindCalendarData(trainer_id) {
        try {
            const monthYear = dayjs().add(step, 'month').format("YYYY-MM")
            const filter = {
                month: monthYear
            };
            const response = await findManyTrainerSchedules(trainer_id, filter);
            if (response && !response.error) {
                setCalendar(response.data);
            } else {
                setCalendar([])
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => {
        console.log('user', user);
        if (user?.trainer_information?.id) {
            const trainer_id = user.trainer_information.id;
            handleFindCalendarData(trainer_id);
        }
    }, [user, step])

    useEffect(() => {
        handleFindManyClasses();
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Sessions"
                breadcrumb={[
                    {
                        title: 'Instructor'
                    },
                    {
                        title: 'Sessions'
                    }
                ]}
                // endAction={(
                //     <Link to="/trainer/sessions/create">
                //         <Button
                //             startIcon={<AddIcon />}
                //             variant='contained'
                //             color='primary'>
                //             Create Session
                //         </Button>
                //     </Link>
                // )}
            />
            {/* <div className="grid grid-cols-3 gap-3 mb-10">
                <div className='p-3 border border-black'>
                    <Link to="/trainer/sessions">
                        {t("Sessions")}
                    </Link>
                </div>
                <div className='p-3 border border-black'>
                    <Link to="/trainer/sessions">
                        {t("Information")}
                    </Link>
                </div>
                <div className='p-3 border border-black'>
                    <Link to="/trainer/sessions">
                        {t("Information")}
                    </Link>
                </div>
            </div> */}
            <Box>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ mb: 3 }}>
                    <FormControl sx={{ width: 220 }}>
                        <InputLabel>Select view</InputLabel>
                        <Select value={view} onChange={(e) => setView(e.target.value)} label="Select view" placeholder='View'>
                            <MenuItem value="day">Today</MenuItem>
                            <MenuItem value="week">This Week</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 220 }}>
                        <InputLabel>Select Class</InputLabel>
                        <Select
                            onChange={(e) => setFilter({ ...filter, class_id: e.target.value })}
                            label="Select Class"
                            placeholder='View'>
                            <MenuItem>All</MenuItem>
                            {
                                Array.isArray(classes) && classes.length ?
                                    classes.map((val) => {
                                        return (
                                            <MenuItem value={val.id} key={val.id}>
                                                {val.title}
                                            </MenuItem>
                                        )
                                    })
                                    : ""
                            }
                        </Select>
                    </FormControl>
                    <Button onClick={() => setStep((step) => step - 1)} variant='outlined'>
                        {t("Prev")}
                    </Button>
                    <Button onClick={() => setStep((step) => step + 1)} variant='outlined'>
                        {t("Next")}
                    </Button>
                </Stack>
                {isLoading ? <PageLoading height={320} /> : ViewComponent[view]}
            </Box>
        </Box>
    );
}