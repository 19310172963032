import { Fragment, useState } from 'react'
import { createMembership } from '@/utils/Memberships'
import { findManyMembershipCategories } from '@/utils/MembershipCategories';
import { findManyClasses } from '@/utils/Classes'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, Stack, Switch } from '@mui/material';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import NavHeader from '@/components/NavHeader'
import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import SelectedCell from '@/components/SelectedCell'
import { useTranslation } from 'react-i18next';

const CreateMemberShipPage = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [formError, setFormError] = useState(false);
    const [isPrivate, setIsPrivate] = useState(0);
    const [isTriggerActive, setIsTriggerActive] = useState(0);
    const [isNotApplyBonus, setIsNotApplyBonus] = useState(0);
    
    const [canBeShared, setCanBeShared] = useState(0);

    const navigate = useNavigate();

    async function handleCreate(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createMembership(token, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', t('Data created successfully!'));
                if (data?.id) {
                    navigate("/admin/memberships/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later!'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let classArray = classes.map((val) => val?.id);
        let categoryArray = categories.map((val) => val?.id);

        const dataForm = {
            title: data.get('title'),
            description: data.get('description'),
            note: data.get('note'),
            regular_price: data.get('regular_price'),
            sale_price: data.get('sale_price'),
            duration_days: data.get('duration_days'),
            sessions: data.get('sessions'),
            max_sessions_per_month: data.get('max_sessions_per_month'),
            is_private: isPrivate ? 1 : 0,
            can_be_share: canBeShared ? 1 : 0,
            trigger_active: isTriggerActive ? 1 : 0,
            not_apply_bonus:  isNotApplyBonus ? 1 : 0,
            academic_classes: classArray,
            categories: categoryArray,
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleCreate(token, dataForm)
        } else {
            showMessage('error', t("One or more input fields are invalid!"));
        }

        setFormError(error);
    }

    const constraints = {
        title: {
            presence: {
                allowEmpty: false
            }
        },
        description: {
            presence: {
                allowEmpty: false
            },
        },
        regular_price: {
            presence: {
                allowEmpty: false
            },
        },
        duration_days: {
            presence: {
                allowEmpty: false
            },
        },
        sessions: {
            presence: {
                allowEmpty: false
            },
        },
        max_sessions_per_month: {
            presence: {
                allowEmpty: false
            },
        },
        academic_classes: {
            presence: {
                allowEmpty: false
            },
        },
        categories: {
            presence: {
                allowEmpty: false
            },
        }
    };

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Create Membership")}
                    breadcrumb={[
                        {
                            title: 'Memberships',
                            link: '/admin/memberships/'
                        },
                        {
                            title: t('Create Membership')
                        }
                    ]}
                    backLink="/admin/memberships"
                />
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                                <FormLabel>{t("Membership Title")}</FormLabel>
                                <TextField
                                    name="title"
                                    error={formError?.title}
                                />
                                {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.description}>
                                <FormLabel>{t("Membership description")}</FormLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    name="description"
                                />
                                {formError?.description ? <FormHelperText>{formError?.description[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 2 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={isPrivate}
                                    onChange={() => setIsPrivate(!isPrivate)}
                                    label={t("Is private membership")} />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 2 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={canBeShared}
                                    onChange={() => setCanBeShared(!canBeShared)}
                                    label={t("Can be shared (Allow user book multiple seats for 1 session)")} 
                                />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 2 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={isNotApplyBonus}
                                    onChange={() => setIsNotApplyBonus(!isNotApplyBonus)}
                                    label={t("Is not apply bonus")} 
                                />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.note}>
                                <FormLabel>{t("Membership note")}</FormLabel>
                                <TextField
                                    name="note"
                                />
                                {formError?.note ? <FormHelperText>{formError?.note[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.regular_price} required>
                                        <FormLabel>{t("Regular price")}</FormLabel>
                                        <TextField
                                            type="number"
                                            name="regular_price"
                                        />
                                        {formError?.regular_price ? <FormHelperText>{formError?.regular_price[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.sale_price}>
                                        <FormLabel>{t("Sale price")}</FormLabel>
                                        <TextField
                                            type="number"
                                            name="sale_price"
                                        />
                                        {formError?.sale_price ? <FormHelperText>{formError?.sale_price[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid> */}
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.duration_days} required>
                                <FormLabel>{t("Duration days")}</FormLabel>
                                <TextField
                                    name="duration_days"
                                    type="number"
                                    error={formError?.duration_days}
                                />
                                {formError?.duration_days ? <FormHelperText>{formError?.duration_days[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{mb: 3}}>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={isTriggerActive}
                                    onChange={() => setIsTriggerActive(!isTriggerActive)}
                                    label={t("Automatically activate membership after registering for a session")} />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.sessions} required>
                                <FormLabel>{t("Sessions (-1 is unlimited)")}</FormLabel>
                                <TextField
                                    name="sessions"
                                    type="number"
                                    error={formError?.sessions}
                                />
                                {formError?.sessions ? <FormHelperText>{formError?.sessions[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.max_sessions_per_month} required>
                                <FormLabel>{t("Max session booked per-month (-1 is unlimited)")}</FormLabel>
                                <TextField
                                    name="max_sessions_per_month"
                                    type="number"
                                    error={formError?.max_sessions_per_month}
                                />
                                {formError?.max_sessions_per_month ? <FormHelperText>{formError?.max_sessions_per_month[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Select Academic classes")}
                                    name="academic_classes"
                                    required={true}
                                    error={formError?.academic_classes}
                                    defaultData
                                    getApi={findManyClasses}
                                    values={classes}
                                    onChange={(value) => {
                                        if (value) {
                                            setClasses((classes) => {
                                                let filterClasses = classes.filter((_v) => _v?.id !== value.id);
                                                filterClasses = [...filterClasses, value];
                                                return filterClasses;
                                            })
                                        }
                                    }}
                                    optionKey="title"
                                >
                                    <SelectedCell
                                        cellLabel={t("Classes")}
                                        data={classes}
                                        onChange={(value) => setClasses(value)}
                                    />
                                </PostPicker>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Membership categories")}
                                    name="membership_categories"
                                    required={true}
                                    error={formError?.categories}
                                    defaultData
                                    getApi={findManyMembershipCategories}
                                    values={categories}
                                    onChange={(value) => {
                                        if (value) {
                                            setCategories((categories) => {
                                                let filterCategories = categories.filter((_v) => _v?.id !== value.id);
                                                filterCategories = [...filterCategories, value];
                                                return filterCategories;
                                            })
                                        }
                                    }}
                                    optionKey="title"
                                >
                                    <SelectedCell
                                        cellLabel={t("Categories")}
                                        data={categories}
                                        onChange={(value) => setCategories(value)}
                                    />
                                </PostPicker>
                            </Box>
                            <FormControl>
                                <Stack direction="row" spacing={1}>
                                    <LoadingButton
                                        variant='contained'
                                        color='primary'
                                        size='lg'
                                        type='submit'
                                        loading={isLoading}
                                    >
                                        {t("Create membership")}
                                    </LoadingButton>
                                </Stack>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Fragment>
    )
}

export default CreateMemberShipPage
