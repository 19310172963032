import { useState, useEffect, Fragment } from 'react';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { findManyOrders } from '@/utils/Orders';
import { useToasterContext } from '@/utils/ToasterContext';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import PageLoading from '@/components/PageLoading';

import { numberWithCommas } from '@/utils/Hooks';
import classNames from 'classnames';
import DateCell from '@/components/DateCell';
import { Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const headCells = [
    {
        id: 'Order ID',
        numeric: false,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'billing_name',
        numeric: false,
        disablePadding: false,
        label: 'Billing name',
    },
    {
        id: 'billing_phone',
        numeric: false,
        disablePadding: false,
        label: 'Billing Phone',
    },
    {
        id: 'billing_email	',
        numeric: false,
        disablePadding: false,
        label: 'Billing email',
    },
    {
        id: 'billing_total',
        numeric: false,
        disablePadding: false,
        label: 'Billing total',
    },
    {
        id: 'payment_status',
        numeric: false,
        disablePadding: false,
        label: 'Payment status',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Created at',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <p sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <p
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </p>
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

export default function EnhancedTable() {

    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pageStatus, setPageStatus] = useState('idle');
    
    const { showMessage, startProgress, completeProgress } = useToasterContext()
    const [total, setTotal] = useState(0);

    const [filter, setFilter] = useState({
        order: "desc",
        paged: 1,
        per_page: 12
    })

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            const response = await findManyOrders(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
            setPageStatus("complete");
        } catch (error) {

        } finally {
            completeProgress();
        }
    }

    useEffect(() => {
        handleFindManyData(filter);
    }, [filter])

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    const Row = ({ rowData }) => {
        const { t } = useTranslation();
        const {
            id,
            title,
            billing_name,
            billing_phone,
            billing_email,
            billing_country,
            billing_city,
            billing_district,
            billing_address,
            billing_total,
            payment_status,
            payment_method,
            created_at,
            user
        } = rowData;
        const [open, setOpen] = useState(false);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={id}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Link to={`/admin/orders/` + id}>
                            <p sx={{fontWeight: 600}}>
                                {id}
                            </p>
                        </Link>
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center">
                            <Link to={`/admin/users/` + user?.id}>
                                <p>
                                    {billing_name}
                                </p>
                            </Link>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <p>
                            <Link to={`tel:${billing_phone}`}>{billing_phone}</Link>
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            <Link to={`mailto:${billing_email}`}>{billing_email}</Link>
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            { billing_total ? numberWithCommas(billing_total) + "₫" : "-"}
                        </p>
                    </TableCell>
                    <TableCell>
                        <div
                            className={classNames("inline-block text-white px-1 text-sm capitalize", {
                                "bg-red-600": payment_status == 'failed',
                                "bg-yellow-600": payment_status == 'pending',
                                "bg-green-600": payment_status == 'success',
                            })}>
                                {payment_status}
                            </div>
                    </TableCell>
                    <TableCell>
                        <DateCell time={created_at}/>
                    </TableCell>
                    <TableCell>
                       <Link to={"/admin/orders/" + id}>
                            <Button size='small' sx={{borderRadius: 0}} variant="contained">View</Button>
                       </Link>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <p
                                    gutterBottom
                                    sx={{ fontWeight: 600 }}>
                                    {t("Order detail")}
                                </p>
                                <Card variant='outlined' sx={{ mb: 3 }}>
                                    <Table
                                        size="small"
                                        aria-label="purchases"
                                        sx={{
                                            ".MuiTableCell-head": {
                                                background: "whitesmoke"
                                            },
                                            ".MuiTableCell-root": {
                                                border: "unset",

                                            }
                                        }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <p sx={{ fontWeight: 600 }}>
                                                       {t("Title")}
                                                    </p>
                                                </TableCell>
                                                <TableCell>
                                                    <p sx={{ fontWeight: 600 }}>
                                                       {t("Payment method")}
                                                    </p>
                                                </TableCell>
                                                <TableCell>
                                                    <p sx={{ fontWeight: 600 }}>
                                                        {t("Address")}
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ border: "unset" }} component="th" scope="row">
                                                    {title}
                                                </TableCell>
                                                <TableCell sx={{ border: "unset" }} component="th" scope="row">
                                                    {payment_method}
                                                </TableCell>
                                                <TableCell>
                                                    {billing_address} {billing_district} {billing_city} {billing_country}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Card>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper variant='plain' sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <EnhancedTableHead />
                        <TableBody>
                            {data.map((row) => {
                                return (
                                    <Row key={row.id} rowData={row} />
                                );
                            })}
                        </TableBody>
                        </Table>
                        {
                            selected.length ? (
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                    isLoading={deleteLoading}
                                    onSubmit={() => handleDeleteMultipeData(selected)}
                                />
                            ) : ""
                        }
                </TableContainer>
            </Paper>
        </Box>
    );
}