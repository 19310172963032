import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import validate from 'validate.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { sentPasswordReset } from '@/utils/Auth';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { useToasterContext } from "@/utils/ToasterContext";
import { getToken, getDataUser } from "@/utils/slice/AuthSlice";
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

export default function SignIn() {

    const { t } = useTranslation();
    const [formError, setFormError] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { token, user, status } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }
        dispatch(getDataUser(token));
    }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            email: data.get('email'),
        }

        const error = validate(dataForm, constraints);
        if (!error) {
            try {
                startProgress();
                setIsLoading(true);
                const response = await sentPasswordReset(dataForm);
                if (response && !response.error) {
                    const { error, message } = response;
                    if (!error) {
                        showMessage('success', message || 'Successful login');
                        navigate("/reset-password/confirmed");
                    } else {
                        showMessage('error', message || 'Wrong account name or password');
                    }
                } else {
                    showMessage('error', 'Wrong account name or password');
                }
            } catch (error) {
                showMessage('error', error);
            } finally {
                completeProgress();
                setIsLoading(false);
            }
        } else {
            showMessage('error', "One or more input fields are not valid");
        }

        setFormError(error);
    };

    const constraints = {
        email: {
            presence: {
                allowEmpty: false,
                message: t('^Email cannot be blank')
            },
            email: {
                message: t('^Invalid email')
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit} noValidate>
                    <Typography sx={{ fontSize: 28, fontWeight: 600, mb: 2 }}>
                        {t("Reset password")}
                    </Typography>
                    <FormControl error={formError?.email} sx={{ width: "100%" }} required>
                        <FormLabel htmlFor="outlined-adornment-password">{t("Email Address")}</FormLabel>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={formError?.email}
                        />
                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                    </FormControl>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color='secondary'
                        loading={isLoading}
                    >
                        Send reset password
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/" variant="body2">
                                Back to home
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/register" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}