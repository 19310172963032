import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import NavHeader from '@/components/NavHeader'
import MediaCard from '@/components/MediaCard';

import { findManyMedia } from '@/utils/TrainerMedia';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const index = () => {

    const [data, setData] = useState([]);
    const { t } = useTranslation(); 
    const { token } = useSelector((state) => state.auth);

    const handleFindManyData = async () => {
        try {
            const response = await findManyMedia(token, {
                per_page: 12
            });
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        handleFindManyData();
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Media"
                breadcrumb={[
                    {
                        title: 'Media'
                    }
                ]}
                endAction={(
                    <Link to="/trainer/media/upload">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            color='primary'>
                            {t("Upload media")}
                        </Button>
                    </Link>
                )}
            />

            <Box>
                <Grid container spacing={3}>
                    {
                        Array.isArray(data) && data.length ?
                            data.map((val) => {
                                return (
                                    <Grid item key={val.id} xs={6} lg={3}>
                                        <MediaCard data={val} />
                                    </Grid>
                                )
                            })
                            : ""
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default index
