import { instance } from "./axiosInstance";

export async function findManyUserWaitingLists(token, params = {}) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params
    };
    const response = await instance
        .get("/user/waiting-lists", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function findOneUserWaitingList(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .get(`/user/waiting-lists/${id}`, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function applySessionFromWaitingList(data) {
    const response = await instance
        .post('/waiting-lists', data)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function findOneUserWaitingListCheckin(token) {
    if (!token) {
        return null;
    }
    const response = await instance
        .get(`/waiting-lists/apply-token/${token}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function TrainerNoShowUser(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .post(`/user/waiting-lists/no-show/${id}`, {}, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function TrainerCheckInUser(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .post(`/user/waiting-lists/checkin/${id}`, {}, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function TrainerCheckOutUser(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .post(`/user/waiting-lists/checkout/${id}`, {}, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function createUserWaitingList(token, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .post("/user/waiting-lists", data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function updateUserWaitingList(token, id, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .put("/user/waiting-lists/" + id, data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function deleteUserWaitingList(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            id: id
        }
    };
    const response = await instance
        .delete("/user/waiting-lists", config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}