import { useState, useEffect, Fragment } from 'react';
import { alpha } from '@mui/material/styles';
import { deleteClass, deleteClasses, findManyClasses } from '@/utils/Classes';
import { useToasterContext } from '@/utils/ToasterContext';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '@/components/ConfirmDelete';
import PageLoading from '@/components/PageLoading';
import FilterTable from '@/components/FilterTable';
import DateCell from '@/components/DateCell';
import NavHeader from '@/components/NavHeader';
import ActionCell from '@/components/ActionCell';

import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const headCells = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'title',
        label: 'Title',
    },
    {
        id: 'capacity',
        label: 'Capacity',
    },
    {
        id: 'trainers',
        label: 'Trainers',
    },
    {
        id: 'created_at',
        label: 'Created at',
    },
    {
        id: 'actions',
        label: 'Actions',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function EnhancedTableToolbar({ numSelected, isLoading, onSubmit }) {
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <p
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {t("selected")}
                    </p>
                    <Tooltip title="Delete">
                        <IconButton onClick={onSubmit}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

export default function EnhancedTable() {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [multipeDelete, setMultipeDelete] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState('idle');
    const { t } = useTranslation();

    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    })

    const { showMessage, startProgress, completeProgress } = useToasterContext();

    const { token } = useSelector((state) => state.auth);

    const handleFindManyData = async () => {
        try {
            startProgress();
            const response = await findManyClasses(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
        } catch (error) {

        } finally {
            setPageStatus("complete");
            completeProgress();
        }
    }

    async function handleDeleteData(id) {
        try {
            setDeleteLoading(true);
            const response = await deleteClass(token, id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    handleFindManyData({});
                    showMessage("success", t("Delete item successully!"))
                }
            } else {
                showMessage("error", t("An error occurred during delete item!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during delete item!"))
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    async function handleDeleteMultipeData(data) {
        try {
            setDeleteLoading(true);
            const response = await deleteClasses(token, {
                ids: data
            });
            if (response && !response.error) {
                handleFindManyData({});
                showMessage("success", t("Delete item successully!"))
            } else {
                showMessage("error", t("An error occurred during delete item!"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred during delete item!"))
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
            setSelected([]);
        }
    }

    useEffect(() => {
        const initFilter = {
            search: searchParams.get('search'),
            order: searchParams.get('order'),
            start_date: searchParams.get('start_date'),
            end_date: searchParams.get('end_date'),
            paged: searchParams.get('paged'),
            per_page: searchParams.get('per_page'),
        };
        let filterParams = {};
        for (const key in initFilter) {
            if (Object.hasOwnProperty.call(initFilter, key)) {
                const element = initFilter[key];
                if (element != null) {
                    filterParams[key] = element;
                }
            }
        }
        setFilter((filter) => ({
            ...filter,
            ...filterParams
        }));
        handleFindManyData(filterParams);
    }, [])

    useEffect(() => {
        if (pageStatus == 'complete') {
            let filterParams = {};
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    const element = filter[key];
                    if (element) {
                        filterParams[key] = element;
                    }
                }
            }
            handleFindManyData(filterParams);
        }
    }, [filter])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    const Row = ({ dataRow }) => {
        const { id, title, trainers, capacity, created_at } = dataRow;
        const isItemSelected = isSelected(id);
        const labelId = `enhanced-table-checkbox-${id}`;
        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, id)}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                    />
                </TableCell>
                <TableCell>{id}</TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                >
                    <Link to={`/admin/classes/${id}`}>
                        <p>
                            <strong className='uppercase'>{title}</strong>
                        </p>
                    </Link>
                </TableCell>
                <TableCell>{capacity}</TableCell>
                <TableCell>{Array.isArray(trainers) && trainers?.length ? trainers.map((val, index) => {
                    return(
                        <Link className='mr-1' key={index} to={`/admin/trainers/${val.id}`}>
                            {val.full_name}{index < (trainers?.length - 1) ? "," : ""}
                        </Link>
                    )
                }) : "-"}</TableCell>
                <TableCell>
                    <DateCell time={created_at}/>
                </TableCell>
                <TableCell>
                    <ActionCell
                        rowData={dataRow}
                        editUrl="/admin/classes/"
                        setDeleteItem={setDeleteItem}
                    />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Classes"
                breadcrumb={[
                    {
                        title: 'classes'
                    }
                ]}
                endAction={(
                    <Link to="/admin/classes/create">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            color='primary'>
                            {t("Create Class")}
                        </Button>
                    </Link>
                )}
            />
            <Box >
                <FilterTable filter={filter} setFilter={setFilter} />
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                        <Table
                            stickyHeader aria-label="sticky table"
                            size='medium'>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                            />
                            <TableBody>
                                {data.map((row, index) => <Row key={index} dataRow={row} />)}
                            </TableBody>
                        </Table>
                        {
                            selected.length ? (
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                    isLoading={deleteLoading}
                                    onSubmit={() => setMultipeDelete(selected)} />
                            ) : ""
                        }
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage={t("Rows per page:")}
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={parseInt(filter?.per_page) || 12}
                        page={parseInt(filter?.paged - 1)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            <ConfirmDelete
                open={deleteItem ? true : false}
                setOpen={setDeleteItem}
                isLoading={deleteLoading}
                onSubmit={() => handleDeleteData(deleteItem)}
            />
            <ConfirmDelete
                open={multipeDelete ? true : false}
                setOpen={setMultipeDelete}
                isLoading={deleteLoading}
                onSubmit={() => handleDeleteMultipeData(multipeDelete)}
            />
        </Box>
    );
}