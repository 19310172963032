import { Box, Typography, Button, Divider, Chip } from '@mui/material'
import { useEffect, useState, Fragment } from 'react'
import { findOneSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { useNavigate, useParams } from 'react-router-dom';

import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import NavHeader from '@/components/NavHeaderHome'

import { createUserSessions, findManyUserSubscriptionsForSession } from '@/utils/UserInfomations'
import { useTranslation } from 'react-i18next'
import Info from '@/components/Info'

import ModalBookSession from './components/ModalBookSession'
import ModalWaitingList from './components/ModalWaitingList'
import ErrorPage from '@/pages/ErrorPage'
import { pusher } from '@/utils/Pusher'
import validate from 'validate.js'

dayjs.extend(customParseFormat);
dayjs.extend(require("dayjs/plugin/isBetween"));

const BookSession = () => {

    const { token, user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { t } = useTranslation();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [waitingListOpen, setWaitingListOpen] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(false);
    const [capacity, setCapacity] = useState(0);
    const [classSessions, setClassSessions] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleFindManyUserSubscriptions = async (token, filter) => {
        try {
            const response = await findManyUserSubscriptionsForSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (Array.isArray(data)) {
                    setSubscriptions(data);
                } else {
                    setSubscriptions([]);
                }
            }
        } catch (error) {

        }
    }

    async function handleFindOneSession() {
        try {
            startProgress();
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        capacity,
                        trainer,
                        class_sessions
                    } = data;
                    const academicClass = data?.class;
                    setClassSessions(class_sessions);
                    setCapacity(capacity);
                    setClass(academicClass);
                    setTrainer(trainer);
                    setDefaultValue(data);
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setPageInit("complete");
            completeProgress();
        }
    }

    useEffect(() => {
        if (token) {
            handleFindManyUserSubscriptions(token, {});
            handleFindOneSession();
        }
    }, [token])

    useEffect(() => {
        const class_types = AcademicClass?.class_types;
        let classTypeInfo = class_types ? JSON.parse(class_types) : null;
        if(Array.isArray(classTypeInfo) && classTypeInfo.length && defaultValue?.class_types){
            let selectedClass = classTypeInfo.find((val) => val?.title == defaultValue?.class_types);
            setSelectedClassType(selectedClass);
        }
    }, [defaultValue, AcademicClass]);

    if (pageInit !== 'complete') {
        return (
            <PageLoading />
        );
    }

    const start = dayjs(defaultValue.start_time);
    const end = dayjs(defaultValue.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, 'minute');
    const sessionDateCompare = dayjs().isAfter(start);
    const validateUsers = Array.isArray(classSessions) && classSessions?.length ? classSessions.filter((val) => val?.status == 'active') : [];
    const hasReachedCapacity = capacity <= validateUsers.length;

    if(!defaultValue){
        return <ErrorPage />
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Book a session")}
                    breadcrumb={[
                        {
                            title: t('Book a session')
                        }
                    ]}
                    backLink="/"
                />
                <div className="max-w-[520px] mx-auto">
                    <Box>
                        <Divider sx={{ mb: 2 }}>
                            <Chip label={t("Session information")} className='uppercase' sx={{borderRadius: 0}}/>
                        </Divider>
                        <Box sx={{ mb: 3 }}>
                            {
                                selectedClassType ? (
                                    <Fragment>
                                        <Typography>
                                            <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                        </Typography>
                                        <Typography className="text-sm" sx={{mb: 3}}>
                                            {t(selectedClassType?.description)}
                                        </Typography>
                                    </Fragment>
                                ) : (
                                    AcademicClass ?
                                        <Fragment>
                                            <Typography>
                                                <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                            </Typography>
                                            <Typography className="text-sm" sx={{mb: 3}}>
                                                {AcademicClass?.description}
                                            </Typography>
                                        </Fragment>
                                        : ""
                                )
                            }
                            {
                                start ?
                                    <Fragment>
                                        <Typography>
                                            {t("on")} <span className='font-bold uppercase'> {start.format('ddd, DD/MM')}</span>
                                        </Typography>
                                        <Typography>
                                            {t("at")} <span className='font-bold uppercase'>{start.format('HH:mm')}</span>
                                        </Typography>
                                    </Fragment>
                                    : ""
                            }
                            {
                                durationInMinutes ?
                                    <Typography>
                                        {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                    </Typography>
                                    : ""
                            }
                            <Typography>
                                {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                            </Typography>

                            {    defaultValue?.is_free_session ? (
                                    <Typography sx={{ mb: 2 }}>
                                        {t("Free to join")} <Info title={t("You can register for this session without having to sign up for any membership plans")}/>
                                    </Typography>
                                ) : ""
                            }
                            
                        </Box>
                    </Box>
                    <div className="text-center">
                        {
                            !sessionDateCompare ? (
                                <Fragment>
                                    {
                                        !hasReachedCapacity ? (
                                            <Button
                                                variant='contained'
                                                onClick={() => setOpen(true)}
                                                color='secondary'
                                                sx={{
                                                    padding: "10px 12px",
                                                    borderRadius: 0,
                                                    display: "block",
                                                    mt: 2,
                                                    mb: 2,
                                                    textAlign: 'center',
                                                    letterSpacing: 2
                                                }}>
                                                {t("Book session")}
                                            </Button>
                                        ): (
                                            <Button
                                                variant='contained'
                                                onClick={() => setWaitingListOpen(true)}
                                                color='secondary'
                                                sx={{
                                                    padding: "10px 12px",
                                                    borderRadius: 0,
                                                    display: "block",
                                                    mt: 2,
                                                    mb: 2,
                                                    textAlign: 'center',
                                                    letterSpacing: 2
                                                }}>
                                                {t("Book session")}
                                            </Button>
                                        )
                                    }
                                </Fragment>
                            ) :""
                        }
                    </div>
                        
            </div>
            </Box>
            {
                defaultValue ? (
                    <Fragment>
                         <ModalBookSession 
                            open={open} 
                            onClose={handleClose} 
                            session={defaultValue} 
                            subscriptions={subscriptions}
                            reload={handleFindOneSession}
                        />
                        <ModalWaitingList 
                            open={waitingListOpen} 
                            onClose={() => setWaitingListOpen(false)} 
                            session={defaultValue} 
                            subscriptions={subscriptions}
                            reload={handleFindOneSession}
                        />
                    </Fragment>
                ) : ""
            }

        </Fragment>
    )
}

export default BookSession