import { useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import validate from 'validate.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, useParams } from 'react-router-dom';
import { changeUserPassword } from '@/utils/Auth';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { useToasterContext } from "@/utils/ToasterContext";
import { useTranslation } from 'react-i18next';

export default function SignIn() {

    const { t } = useTranslation();
    const [formError, setFormError] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useParams();

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            password: data.get('password'),
            confirm_password: data.get('confirm_password'),
            token: token
        }

        const error = validate(dataForm, constraints);
        if (!error) {
            try {
                startProgress();
                setIsLoading(true);
                const response = await changeUserPassword(dataForm);
                if (response && !response.error) {
                    const { error } = response;
                    if (!error) {
                        showMessage('success', t('You have successfully changed your password'));
                        navigate('/login');
                    } else {
                        showMessage('error', response?.data?.message || t('An error occurred, please try again later'));
                    }
                } else {
                    showMessage('error', t('An error occurred, please try again later'));
                }
            } catch (error) {
                showMessage('error', error);
            } finally {
                completeProgress();
                setIsLoading(false);
            }
        } else {
            showMessage('error', t("One or more input fields are not valid"));
        }

        setFormError(error);
    };

    const constraints = {
        password: {
            presence: {
                allowEmpty: false,
                message: t('^Password cannot be blank')
            },
            length: {
                minimum: 6,
                message: t('^Password must have at least 6 characters')
            }
        },
        confirm_password: {
            presence: {
                allowEmpty: false,
                message: t('^Password cannot be blank')
            },
            length: {
                minimum: 6,
                message: t('^Password must have at least 6 characters')
            },
            equality: {
                attribute: 'password',
                message: t('^The confirmation password must match the entered password')
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit} noValidate>
                    <Typography sx={{ fontSize: 28, fontWeight: 600, mb: 2 }}>
                        {t("Change your password")}
                    </Typography>
                    <FormControl error={formError?.password} sx={{ width: "100%", mb: 2 }} required>
                        <FormLabel>{t("Password")}</FormLabel>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={formError?.password}
                        />
                        {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.password} sx={{ width: "100%", mb: 2 }} required>
                        <FormLabel>{t("Confirm Password")}</FormLabel>
                        <TextField
                            required
                            fullWidth
                            name="confirm_password"
                            type="password"
                            id="confirm_password"
                            autoComplete="confirm-password"
                            error={formError?.confirm_password}
                        />
                        {formError?.confirm_password ? <FormHelperText>{formError?.confirm_password[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color='secondary'
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                    >
                        Change your password
                    </LoadingButton>
                </Box>
            </Box>
        </Container>
    );
}