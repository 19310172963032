import { Box, Button, Grid, Typography, FormControlLabel, Switch, TableContainer  } from '@mui/material'
import { useState, useCallback } from 'react'
import { createTrainer } from '@/utils/Trainers'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import validate from 'validate.js'
import ThumbnailPicker from '@/components/ThumbnailPicker'
import PostPicker from '@/components/PostPicker'

import { LoadingButton } from '@mui/lab'
import { findManyUsers } from '@/utils/Users'
import { useNavigate } from 'react-router-dom'
import { TimePicker } from '@mui/x-date-pickers'
import { v4 as uuidv4 } from 'uuid';
import { findManyClasses } from '@/utils/Classes'

import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from 'dayjs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { IconButton, Paper, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles';
import { debounce } from '@/utils/Hooks'

const CreateClass = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(false);
    const [formError, setFormError] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [TrainerSessions, setTrainerSessions] = useState([]);
    const [AcademicClasses, setAcademicClasses] = useState([]);

    const navigate = useNavigate();

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = createTrainer(token, data)
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', t('Data created successfully!'));
                if (data?.id) {
                    navigate("/trainer/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const schedule = JSON.stringify(TrainerSessions);
        const is_have_private = data.get('is_have_private');

        const dataForm = {
            full_name: data.get('full_name'),
            birthday: data.get('birthday'),
            gender: data.get('gender'),
            email: data.get('email'),
            phone_number: data.get('phone_number'),
            address: data.get('address'),
            description: data.get('description'),
            bonus: data.get('bonus'),
            bonus_threshold: data.get('bonus_threshold'),
            start_date: data.get('start_date'),
            user_id: user?.id,
            status: data.get('status'),
            academic_classes: AcademicClasses,
            thumbnail: thumbnail?.id,
            is_have_private: is_have_private == 'on' ? 1 : 0,
            schedule
        }

        const error = validate(dataForm, constraints);
        if (!error) {
            handleCreate(dataForm);
        }
        setFormError(error);
    }

    function handleAddSchedule() {
        setTrainerSessions((TrainerSessions) => ([
            ...TrainerSessions,
            {
                id: uuidv4(),
                start_time: "00:00:00",
                end_time: "00:00:00"
            }
        ]))
    }

    function handleUpdateSchedule(data) {
        const updateSessions = TrainerSessions.map((val) => val.id == data.id ? data : val);
        setTrainerSessions(updateSessions);
    }

    function handleDeleteSchedule(id) {
        const removeSessions = TrainerSessions.filter((_v) => _v.id !== id);
        setTrainerSessions(removeSessions);
    }

    const SelectedCell = ({ cellLabel, data, onChange }) => {

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 0,
            },
        }));
    
        const handleChange = useCallback(debounce((id, field, value) => {
            const updatedData = data.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            );
            onChange(updatedData);
        }, 300), [data, onChange]);
    
        return (
            <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                <TableCell>{cellLabel}</TableCell>
                                <TableCell>{t("Unit cost")}</TableCell>
                                <TableCell>{t("Bonus")}</TableCell>
                                <TableCell>{t("Bonus threshold")}</TableCell>
                                <TableCell align="right">{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(data) && data.length ? data.map((row, index) => (
                                <StyledTableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            type="number"
                                            name="unit_cost"
                                            defaultValue={row.unit_cost}
                                            onChange={(e) => handleChange(row.id, 'unit_cost', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            type="number"
                                            name="bonus"
                                            defaultValue={row.bonus}
                                            onChange={(e) => handleChange(row.id, 'bonus', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            type="number"
                                            name="bonus_threshold"
                                            defaultValue={row.bonus_threshold}
                                            onChange={(e) => handleChange(row.id, 'bonus_threshold', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => onChange(data.filter((_v) => _v.id !== row.id))}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            )) : ""}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    };

    const constraints = {
        full_name: {
            presence: { allowEmpty: false }
        },
        email: {
            presence: { allowEmpty: false }
        },
        gender: {
            presence: { allowEmpty: false }
        },
        status: {
            presence: { allowEmpty: false }
        },
        user_id: {
            presence: { allowEmpty: false }
        },
        academic_classes: {
            presence: { allowEmpty: false }
        }
    };

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Create Instructor")}
                    breadcrumb={[
                        {
                            title: t('Create Instructor')
                        }
                    ]}
                    backLink="/admin/trainers"
                />
                <Box  component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.full_name} required>
                                <FormLabel>{t("Full Name")}</FormLabel>
                                <TextField
                                    name="full_name"
                                    error={formError?.full_name}
                                />
                                {formError?.full_name ? <FormHelperText>{formError?.full_name[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormLabel>{t("Description")}</FormLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    name="description"
                                />
                            </FormControl>
                            <div className="grid grid-cols-2 gap-3">
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email} required>
                                    <FormLabel>{t("Email")}</FormLabel>
                                    <TextField
                                        name="email"
                                        type="email"
                                        error={formError?.email}
                                    />
                                    {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                                </FormControl>
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.phone_number}>
                                    <FormLabel>{t("Phone number")}</FormLabel>
                                    <TextField
                                        name="phone_number"
                                        type="tel"
                                        error={formError?.phone_number}
                                    />
                                    {formError?.phone_number ? <FormHelperText>{formError?.phone_number[0]}</FormHelperText> : ""}
                                </FormControl>
                            </div>
                            <div className="grid grid-cols-2 gap-3">
                                <FormControl sx={{ width: "100%", mb: 3 }} required>
                                    <FormLabel id="demo-simple-select-label">{t("Gender")}</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="gender"
                                    >
                                        <MenuItem value={'male'}>{t("Male")}</MenuItem>
                                        <MenuItem value={'female'}>{t("Female")}</MenuItem>
                                    </Select>
                                    {formError?.gender ? <FormHelperText>{formError?.gender[0]}</FormHelperText> : ""}
                                </FormControl>
                                <FormControl sx={{ width: "100%", mb: 3 }} required>
                                    <FormLabel id="demo-simple-select-label">{t("Status")}</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="status"
                                    >
                                        <MenuItem value={'active'}>{t("Active")}</MenuItem>
                                        <MenuItem value={'inactive'}>{t("Inactive")}</MenuItem>
                                    </Select>
                                    {formError?.status ? <FormHelperText>{formError?.status[0]}</FormHelperText> : ""}
                                </FormControl>
                            </div>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.address}>
                                <FormLabel>{t("Address")}</FormLabel>
                                <TextField
                                    name="address"
                                    error={formError?.address}
                                />
                                {formError?.address ? <FormHelperText>{formError?.address[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Select user")}
                                    name="user"
                                    defaultData={user}
                                    values={[user]}
                                    required={true}
                                    error={formError?.user_id}
                                    getApi={findManyUsers}
                                    onChange={(value) => setUser((value))}
                                    optionKey="email"
                                    previewKey="id"
                                />
                            </Box>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={t("Private bookings are allowed")}
                                    name="is_have_private"
                                />
                            </FormControl>
                            <Typography sx={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    mb: 2
                                }}>
                                {t("Instructor classes")}
                            </Typography>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Select classes")}
                                    name="academic_classes"
                                    defaultData
                                    getApi={findManyClasses}
                                    values={AcademicClasses}
                                    required={true}
                                    error={formError?.academic_classes}
                                    onChange={(value) => {
                                        if (value) {
                                            setAcademicClasses((AcademicClasses) => {
                                                let filterClasses = AcademicClasses.filter((_v) => _v?.id !== value.id);
                                                filterClasses = [...filterClasses, value];
                                                return filterClasses;
                                            })
                                        }
                                    }}
                                    optionKey="title"
                                >
                                    <SelectedCell
                                        cellLabel={t("Classes")}
                                        data={AcademicClasses}
                                        onChange={(value) => setAcademicClasses(value)}
                                    />
                                </PostPicker>
                            </Box>
                            {/* <Box>
                                <Typography sx={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    mb: 2
                                }}>
                                    {t("Private instructor schedule")}
                                </Typography>
                                {
                                    Array.isArray(TrainerSessions) && TrainerSessions.length ?
                                        TrainerSessions.map((val, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Grid container spacing={3} >
                                                        <Grid item xs={6}>
                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                                                <FormLabel>Start hour</FormLabel>
                                                                <TimePicker
                                                                    name="start_time"
                                                                    value={val?.start_time ? dayjs(val.start_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                    onChange={(value) => handleUpdateSchedule({ ...val, start_time: dayjs(value).format("HH:mm:ss") })}
                                                                />
                                                                {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                                                <FormLabel>{t("End hour")}</FormLabel>
                                                                <TimePicker
                                                                    name="end_time"
                                                                    value={val?.end_time ? dayjs(val.end_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                    onChange={(value) => handleUpdateSchedule({ ...val, end_time: dayjs(value).format("HH:mm:ss") })}
                                                                />
                                                                {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Button
                                                            onClick={() => handleDeleteSchedule(val.id)}
                                                            startIcon={<DeleteIcon />}>
                                                            {t("Delete schedule")}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                        : ""
                                }
                                <Button
                                    variant='contained'
                                    onClick={() => handleAddSchedule()}>
                                    {t("Add schedule")}
                                </Button>
                            </Box> */}
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ mb: 3, width: "100%" }}>
                                <FormLabel id="demo-multiple-name-label">Thumbnail</FormLabel>
                                <ThumbnailPicker value={thumbnail} onChange={(Image) => setThumbnail(Image)} />
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='secondary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                   { t("Create Instructor")}
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default CreateClass
