import { instance } from "./axiosInstance";

export async function findManyUsers(params = {}) {
    const config = {
        params: params
    };
    const response = await instance
        .get("/users", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}


export async function findOneUser(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .get(`/users/${id}`, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function findManyUserSessions(token, id, params = {}) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params
    };
    const response = await instance
        .get("/users/" + id + "/sessions", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function createUser(token, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .post("/users", data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function updateUser(token, id, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .patch("/users/" + id, data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function deleteUser(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const response = await instance
        .delete("/users/" + id, config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}


export async function deleteUsers(token, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: data
    };
    const response = await instance
        .delete("/users", config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}
