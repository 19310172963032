import { Fragment, useEffect } from 'react';;
import { Outlet, useNavigate } from 'react-router-dom';
import { getToken } from "@/utils/slice/AuthSlice";
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

export default function Account() {

    const { token, status } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getToken());
    }, []);

    useEffect(() => {
        if (status == "complete") {
            if (!token) {
                navigate('/login')
            }
        }
    }, [status, token])

    return (
        <Fragment>
            <Box>
                <Outlet />
            </Box>
        </Fragment>
    );
}