import { Box, Grid, IconButton, Table, TableCell, TableBody, TableHead, TableRow, FormControlLabel, FormGroup, Checkbox, Select, MenuItem, Tooltip, TableContainer } from '@mui/material'
import { Fragment, useEffect, useRef, useState } from 'react'
import { createSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from 'dayjs'

import { styled } from '@mui/material/styles';
import { findManyUsers } from '@/utils/Users'
import { findManyClasses } from '@/utils/Classes'
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Info from '@/components/Info'
import { useTranslation } from 'react-i18next'

const CreateSession = () => {
    const { t } = useTranslation(); 
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultSchedule, setDefaultSchedule] = useState(dayjs());
    const [bulkCreate, setBulkCreate] = useState(false);
    const [step, setStep] = useState(0);
    const [trainerOptions, setTrainerOptions] = useState([]);
    const [classTypes, setClassTypes] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(false);
    const formRef = useRef();

    const [bulkSchedule, setBulkSchedule] = useState({
        mon: false,
        tue: false,
        wed: false,
        thur: false,
        fri: false,
        sat: false,
        sun: false
    });
    
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const schedule = searchParams.get("schedule");
            const step = searchParams.get("step");
            if (schedule) {
                setDefaultSchedule(dayjs(schedule, "DD/MM"));
            }
            if(step){
                setStep(step);
            }
        } catch (error) {
            console.error("error", error);
        }
    }, [])

    async function handleCreate(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const sent_notify = data?.sent_notify;
            const response = await createSession(token, data);
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    showMessage('success', t('Data created successfully!'));
                    if(sent_notify){
                        showMessage('success', t('The email has been sent successfully to all users and trainers!'));
                    }
                    if (data?.id) {
                        navigate("/admin/sessions/" + data.id);
                    }
                } else {
                    showMessage('error', t('An error occurred, please try again later!'));
                }
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later!'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleChangeClass(value){
        if(value){
            const { trainers, class_types } = value;
            setClassTypes(JSON.parse(class_types) || []);
            setTrainerOptions(trainers);
            setClass(value)
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user_ids = users.map((val) => val.id);
        const schedule_date = dayjs(data.get('schedule'), "DD/MM/YYYY").format('YYYY-MM-DD');
        const start_time = dayjs(data.get('start_time'), 'HH:mm').format("HH:mm:ss");
        const end_time = dayjs(data.get('end_time'), 'HH:mm').format("HH:mm:ss");
        const duration_minutes  = dayjs(data.get('end_time'), 'hh:mm').diff(dayjs(data.get('start_time'), 'hh:mm'), 'minute');

        const notify = data.get('sent_notify');
        const is_free_session = data.get('is_free_session');
        const capacity = data.get('capacity');
        const class_types = data.get('class_types');
        const bulk_schedule = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].filter(day => bulkSchedule[day]);

        const validateForm = {
            academic_class_id: AcademicClass?.id,
            capacity: data.get('capacity'),
            schedule: data.get('schedule'),
            start_time: data.get('start_time'),
            end_time: data.get('end_time'),
            bulk_schedule: bulk_schedule,
            class_types: class_types,
            trainer_id: selectedTrainer,
        };

        const dataForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
            user_ids: user_ids,
            bulk_schedule: bulk_schedule,
            bulk_create: bulkCreate,
            duration_minutes: duration_minutes,
            class_types: class_types,
            step: step,
            sent_notify: notify == 'on' ? 1 : 0,
            is_free_session: is_free_session == 'on' ? 1 : 0
        }

        try {
            const error = validate(validateForm, constraints);
            if (!error) {
                handleCreate(token, dataForm);
            } else {
                showMessage("error", "One or more input fields are invalid!")
            }
            setFormError(error);
        } catch (error) {

        }
    }

    function handleChangeClassType(e) {
        const form = formRef.current;
        const selectedClassType = classTypes.find((val) => val.title == e.target.value);
        form.elements['capacity'].value = selectedClassType?.capacity;
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || "must be after start time";
        }
    };
    

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }

    let constraints = {
        trainer_id: {
            presence: { allowEmpty: false }
        },
        academic_class_id: {
            presence: { allowEmpty: false }
        },
        class_types: {
            presence: { allowEmpty: false }
        },
        capacity: {
            presence: { allowEmpty: false }
        },
        start_time: {
            presence: { allowEmpty: false },
        },
        end_time: {
            presence: { allowEmpty: false },
            timeAfter: {
                message: "must be after start time"
            }
        }
    };

    if(!bulkCreate){
        constraints = {
            ...constraints,  
            schedule: {
                presence: { allowEmpty: false }
            }
        }
    } else {
        constraints = {
            ...constraints,  
            bulk_schedule: {
                presence: { allowEmpty: false }
            }
        }
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } = bulkSchedule;

    const handleChange = (event) => {
        setBulkSchedule({
          ...bulkSchedule,
          [event.target.name]: event.target.checked,
        });
      };


    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title={t("Create Session")}
                breadcrumb={[
                    {
                        title: t('Sessions'),
                        slug: '/admin/session'
                    },
                    {
                        title: t('Create Session')
                    }
                ]}
                backLink={"/admin/sessions?step=" + step}
            />
            <Box component="form" onSubmit={handleSubmit} ref={formRef}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <PostPicker
                            label={t("Select class")}
                            name="academic_class_id"
                            defaultData
                            getApi={findManyClasses}
                            values={AcademicClass}
                            onChange={(value) => handleChangeClass(value)}
                            error={formError?.academic_class_id}
                            optionKey="title"
                            required
                        />
                        {
                            Array.isArray(classTypes) && classTypes.length ? 
                                <FormControl fullWidth sx={{mb: 3}} error={formError?.class_types} required>
                                    <FormLabel>{t("Class Type")}</FormLabel>
                                    <Select
                                        name="class_types"
                                        onChange={(e) => handleChangeClassType(e)}>
                                        {
                                            classTypes.map((val, index) => {
                                                return (
                                                    <MenuItem key={index} value={val.title}>
                                                        {val.title}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    {formError?.class_types ? <FormHelperText>{formError?.class_types[0]}</FormHelperText> : ""}
                                </FormControl>
                        : ""
                        }
                        {
                            Array.isArray(trainerOptions) && trainerOptions.length ? 
                            (
                                <FormControl error={formError?.trainer_id} sx={{ width: "100%", mb: 3 }} required>
                                    <FormLabel component="legend">{t("Trainer")}</FormLabel>
                                    <Select
                                        name="trainer"
                                        value={selectedTrainer}
                                        onChange={(e) => setSelectedTrainer(e.target.value)}
                                        fullWidth
                                    >
                                        {
                                            trainerOptions.map((val) => {
                                                return (
                                                    <MenuItem key={val.id} value={val.id}>{val.full_name} {val?.email ? `(${val.email})` : ""}</MenuItem>
                                                )
                                            })
                                        }
                                </Select>
                                {formError?.trainer_id ? <FormHelperText>{formError?.trainer_id[0]}</FormHelperText> : ""}
                            </FormControl>
                            ) : ""
                        }
                        {/* <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label={
                                    <Box>
                                        {t("Create schedule for another days of week")} <Info title={t("Create multiple recurring Sessions during the week")} />
                                    </Box>
                                }
                                checked={bulkCreate}
                                onChange={() => setBulkCreate(!bulkCreate)}
                            />
                        </FormControl> */}
                        {
                            bulkCreate ? 
                                <Fragment>
                                    <FormControl error={formError?.bulk_schedule}  sx={{ width: "100%", mb: 3 }} component="fieldset" variant="standard">
                                        <FormLabel component="legend">
                                            {t("Assign schedule")}
                                        </FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Monday} onChange={handleChange} name="Monday" />
                                                }
                                                label={t("Monday")}
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Tuesday} onChange={handleChange} name="Tuesday" />
                                                }
                                                label={t("Tuesday")}
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Wednesday} onChange={handleChange} name="Wednesday" />
                                                }
                                                label={t("Wednesday")}
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Thursday} onChange={handleChange} name="Thursday" />
                                                }
                                                label={t("Thursday")}
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Friday} onChange={handleChange} name="Friday" />
                                                }
                                                label={t("Friday")}
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Saturday} onChange={handleChange} name="Saturday" />
                                                }
                                                label={t("Saturday")}
                                            />
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={Sunday} onChange={handleChange} name="Sunday" />
                                                }
                                                label={t("Sunday")}
                                            />
                                        </FormGroup>
                                        {formError?.bulk_schedule ? <FormHelperText>{formError?.bulk_schedule[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Fragment>
                                : (
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.schedule} required>
                                    <FormLabel>{t("Schedule")}</FormLabel>
                                    <DatePicker
                                        name="schedule"
                                        value={defaultSchedule ? defaultSchedule : ""}
                                    />
                                    {formError?.schedule ? <FormHelperText>{formError?.schedule[0]}</FormHelperText> : ""}
                                </FormControl>
                                )
                        }
                        <Grid container spacing={3} >
                            <Grid item xs={6}>
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                    <FormLabel>{t("Start time")}</FormLabel>
                                    <TimePicker
                                        name="start_time"
                                    />
                                    {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                    <FormLabel>{t("End time")}</FormLabel>
                                    <TimePicker
                                        name="end_time"
                                    />
                                    {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                            <FormLabel>{t("Capacity")}</FormLabel>
                            <TextField
                                name="capacity"
                                type="number"
                                error={formError?.capacity}
                            />
                            {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label={
                                    <Box>
                                        {t("This session is provided free of charge to registered users")} <Info title={t("Users who do not need a subscription can still register")} />
                                    </Box>
                                }
                                name="is_free_session"
                            />
                        </FormControl>
                        {/* <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                                <Table
                                    stickyHeader aria-label="sticky table"
                                    size='medium'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                                    <TableCell>{t("Users / Shared users")}</TableCell>
                                                    <TableCell align="right">{t("Actions")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users.map((row, index) => (
                                                    <StyledTableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {(index + 1)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.display_name || row?.email}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton onClick={() => setUsers((users) => users.filter((_v, _i) => _v.id !== row.id))}>
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                </TableContainer>
                            </Paper>
                        </FormControl> */}
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label={t("Send an email confirmed to notify trainer")}
                                name="sent_notify"
                            />
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}
                            >
                                {t("Create session")}
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreateSession
