import { Box, Grid, IconButton, Table, TableCell, TableBody, TableHead, TableRow, Switch, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import NavHeader from '@/components/NavHeader'
import { createSession } from '@/utils/TrainerSessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { styled } from '@mui/material/styles';
import { findManyUsers } from '@/utils/Users'
import { findManyClasses } from '@/utils/Classes'
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate, useSearchParams } from 'react-router-dom'

import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from 'dayjs'

const CreateSession = () => {

    const { user, token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultSchedule, setDefaultSchedule] = useState(dayjs());

    const navigate = useNavigate();

    useEffect(() => {
        const schedule = searchParams.get("schedule");
        try {
            if (schedule) {
                setDefaultSchedule(dayjs(schedule, "MM/DD"));
            }
        } catch (error) {
            console.error("error", error);
        }
    }, [])

    console.log('user', user);

    async function handleCreate(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createSession(token, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data created successfully!');
                if (data?.id) {
                    navigate("/trainer/sessions/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user_ids = users.map((val) => val.id);
        const schedule_date = dayjs(data.get('schedule')).format('YYYY-MM-DD');
        const start_time = schedule_date + " " + dayjs(data.get('start_time'), 'hh:mm A').format("HH:mm:ss");
        const end_time = schedule_date + " " + dayjs(data.get('end_time'), 'hh:mm A').format("HH:mm:ss");;

        const validateForm = {
            title: data.get('title'),
            description: data.get('description'),
            trainer_id: user?.id,
            academic_class_id: AcademicClass?.id,
            capacity: data.get('capacity'),
            schedule: data.get('schedule'),
            start_time: data.get('start_time'),
            end_time: data.get('end_time'),
        };

        const dataForm = {
            title: data.get('title'),
            description: data.get('description'),
            trainer_id: user?.id,
            academic_class_id: AcademicClass?.id,
            capacity: data.get('capacity'),
            start_time: start_time,
            end_time: end_time,
            user_ids: user_ids,
            sent_notify: data.get('sent_notify')
        }

        try {
            const error = validate(validateForm, constraints);
            if (!error) {
                handleCreate(token, dataForm);
            } else {
                showMessage("error", "One or more input fields are invalid!")
            }
            setFormError(error);
        } catch (error) {

        }
    }

    var daysOfWeek = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' }
    ];

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || "must be after start time";
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }

    const constraints = {
        title: {
            presence: { allowEmpty: false, message: "is required" },
        },
        description: {
            presence: { allowEmpty: false, message: "is required" },
        },
        trainer_id: {
            presence: { allowEmpty: false, message: "is required" }
        },
        academic_class_id: {
            presence: { allowEmpty: false, message: "is required" }
        },
        schedule: {
            presence: { allowEmpty: false, message: "is required" }
        },
        capacity: {
            presence: { allowEmpty: false, message: "is required" }
        },
        start_time: {
            presence: { allowEmpty: false, message: "is required" }
        },
        end_time: {
            presence: { allowEmpty: false, message: "is required" },
            timeAfter: {
                message: "must be after start time"
            }
        }
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Create Session"
                    breadcrumb={[
                        {
                            title: 'Sessions',
                            slug: '/session'
                        },
                        {
                            title: 'Create Session'
                        }
                    ]}
                    backLink="/trainer/sessions"
                />
                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                                <FormLabel>Session Title</FormLabel>
                                <TextField
                                    name="title"
                                    error={formError?.title}
                                />
                                {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.description} required>
                                <FormLabel>Session description</FormLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    name="description"
                                />
                                {formError?.description ? <FormHelperText>{formError?.description[0]}</FormHelperText> : ""}
                            </FormControl>
                            <PostPicker
                                label="Class"
                                name="academic_class_id"
                                defaultData
                                getApi={findManyClasses}
                                values={AcademicClass}
                                onChange={(value) => setClass(value)}
                                error={formError?.academic_class_id}
                                optionKey="title"
                                required
                            />
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.schedule} required>
                                <FormLabel>Schedule</FormLabel>
                                <DatePicker
                                    name="schedule"
                                    value={defaultSchedule ? defaultSchedule : ""}
                                />
                                {formError?.schedule ? <FormHelperText>{formError?.schedule[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3} >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                        <FormLabel>Start hour</FormLabel>
                                        <TimePicker
                                            name="start_time"
                                        />
                                        {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                        <FormLabel>End hour</FormLabel>
                                        <TimePicker
                                            name="end_time"
                                        />
                                        {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                                <FormLabel>Capacity</FormLabel>
                                <TextField
                                    name="capacity"
                                    type="number"
                                    error={formError?.capacity}
                                />
                                {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select Subcription"
                                    name="user"
                                    defaultData
                                    getApi={findManyUsers}
                                    onChange={(value) => {
                                        if (value) {
                                            setUsers((users) => {
                                                let filterUsers = users.filter((_v) => _v?.id !== value.id);
                                                filterUsers = [...filterUsers, value];
                                                return filterUsers;
                                            })
                                        }
                                    }}
                                    optionKey="email"
                                    previewKey="id"
                                />
                            </Box>
                            
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                                <FormLabel>Subcriptions</FormLabel>
                                <Paper>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: 60 }}>No</TableCell>
                                                <TableCell>Subscription</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map((row, index) => (
                                                <StyledTableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {(index + 1)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.display_name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton onClick={() => setUsers((users) => users.filter((_v) => _v?.id !== row.id))}>
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label="Sent an email notify to all subscriptions and trainer"
                                    name="sent_notify"
                                />
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    Create session
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default CreateSession
