import { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useToasterContext } from '@/utils/ToasterContext';
import { findManyUserSessions } from '@/utils/UserInfomations';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PageLoading from '@/components/PageLoading';
import WeekView from './WeekView';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import NavHeader from '@/components/NavHeader';
import { findManyUserWaitingLists } from '@/utils/UserWaitingList';

import FilterTable from '@/components/FilterTable';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import DateCell from '@/components/DateCell';
import ActionCell from '@/components/ActionCell';

const headCells = [
    {
        id: 'class',
        numeric: false,
        label: 'Class',
    },
    {
        id: 'users',
        label: 'Instructor',
    },
    {
        id: 'start_time',
        label: 'Start in',
    },
    {
        id: 'duration',
        label: 'Duration',
    },
    {
        id: 'action',
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    const { t } = useTranslation();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <p sx={{ fontWeight: 600 }}>
                            {t(headCell.label)}
                        </p>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected, isLoading, onSubmit } = props;
    const { t } = useTranslation();
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <p
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {t("selected")}
                    </p>
                    <Tooltip title="Delete" isLoading={isLoading} onClick={() => onSubmit()}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

const UserWaitingList = () => {

    const navigate = useNavigate();

    const { token, user, status } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { t } = useTranslation();

    const [pageStatus, setPageStatus] = useState('idle');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [step, setStep] = useState(0);
    const [selected, setSelected] = useState([]);

    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_time: false,
        end_time: false,
    });

    const handleFindManyData = async (token, filter) => {
        try {
            startProgress();
            setPageStatus("idle");
            const response = await findManyUserWaitingLists(token, filter);
            if (response && !response.error) {
                const { data, meta } = response;
                setData(data || []);
                setTotal(meta?.total || 0);
            }
            setPageStatus("complete");
        } catch (error) {
            console.error('error', error);
        } finally {
            completeProgress();
            setPageStatus("complete")
        }
    }

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(token, filter);
        }
    }, [filter])

    useEffect(() => {
        const start_time = dayjs().add(step, "week").startOf('week').format('YYYY-MM-DD HH:mm:ss');
        const end_time = dayjs().add(step, "week").endOf('week').format('YYYY-MM-DD HH:mm:ss');
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step])

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }


    const Row = ({ rowData }) => {

        const {
            id,
            session,
            billing_name,
            subscription,
            created_at,
            apply_token,
            user
        } = rowData;
        const [open, setOpen] = useState(false);

        const start = session?.start_time ? dayjs(session.start_time) : "-";
        const end = session?.end_time ? dayjs(session.end_time): "-";

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={id}
                    className='!text-sm'
                    onClick={() => setOpen(!open)}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <p>
                            {id}
                        </p>
                    </TableCell>
                    <TableCell>
                       <p>{session?.class_types}</p>
                    </TableCell>
                    <TableCell>
                        <p>
                           {session?.trainer?.full_name}
                        </p>
                    </TableCell>
                    <TableCell>{start.format("HH:mm, DD/MM/YYYY")}</TableCell>
                    <TableCell>{session?.duration_minutes} {t("minutes")}</TableCell>
                    <TableCell sx={{ width: 120}}>
                        <Button 
                            onClick={() => navigate("/account/waiting-list/" + id)}
                            variant='contained' 
                            disabled={apply_token}
                            sx={{borderRadius: 0, background: "#000"}}>
                            {t("View")}
                        </Button>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box>
            <NavHeader
                title={t("Account")}
                breadcrumb={[
                    {
                        title: t('Account')
                    }
                ]}
                endAction={(
                    <Button variant='contained' sx={{borderRadius: 0, background: "#000"}}>
                        {t("My waiting list")}
                    </Button>
                )}
            />
               <Box>
                <FilterTable filter={filter} setFilter={setFilter} />
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                        <Table
                            stickyHeader aria-label="sticky table"
                            size='medium'>
                            <EnhancedTableHead />
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {
                            selected.length ? (
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                    isLoading={deleteLoading}
                                    onSubmit={() => handleDeleteMultipeData(selected)}
                                />
                            ) : ""
                        }
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={parseInt(filter?.per_page) || 12}
                        page={parseInt(filter?.paged - 1)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Box>
    )
}

export default UserWaitingList
