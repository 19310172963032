import { Box, IconButton, Grid, Table, TableCell, TableBody, TableHead, TableRow, Switch, FormControlLabel, Button, Select, MenuItem, Stack, TableContainer, Modal, Typography, Divider } from '@mui/material'
import { useEffect, useState, useRef, Fragment } from 'react'
import NavHeader from '@/components/NavHeader'
import { updateSession, findOneSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { styled } from '@mui/material/styles';
import { findManyClasses } from '@/utils/Classes'
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { approveWaitingList } from '@/utils/WaitingList'
import { deleteUserSession, updateUserSessions } from '@/utils/UserInfomations'

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from 'dayjs'
import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import NotifySession from './Components/NotifySession'
import Info from '@/components/Info'
import classNames from 'classnames'
import BookForSession from './BookForSession'
import DateCell from '@/components/DateCell'
import { findManyUsers } from '@/utils/Users'

dayjs.extend(customParseFormat);

const CreateSession = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultValue, setDefaultValue] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [trainer, setTrainer] = useState(false);
    const [AcademicClass, setClass] = useState([]);
    const [classSessions, setClassSessions] = useState([]);
    const [notify, setNotify] = useState(false);
    const [classTypes, setClassTypes] = useState([]);
    const [trainerOptions, setTrainerOptions] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(false);
    const [step, setStep] = useState(0);
    const [openAnotherBook, setOpenAnotherBook] = useState(false);
    const [waitingList, setWaitingList] = useState([]);
    const [isLoadingApprove, setIsLoadingApprove] = useState(false);
    const [deleteSession, setDeleteSession] = useState(false);
    const [unSendEmail, setUnsendEmail] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const [sessionUpdate, setSessionUpdate] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);

    const formRef = useRef();
    
    const navigate = useNavigate();

    useEffect(() => {
        const step = searchParams.get("step");
        if(step){
            setStep(step);
        }
    }, [])

    async function handleFindOne() {
        try {
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        trainer,
                        trainer_id,
                        waiting_list,
                        class_sessions
                    } = data;
                    setClassSessions(class_sessions);
                    const academicClass = data?.class;
                    if(Array.isArray(academicClass?.trainers) && academicClass?.trainers?.length){
                        setTrainerOptions(academicClass?.trainers || []);
                        setSelectedTrainer(trainer_id);
                    }
                    setWaitingList(waiting_list);
                    setClassTypes(JSON.parse(academicClass?.class_types) || []);
                    setClass(academicClass);
                    setTrainer(trainer);
                    setDefaultValue(data);
                }
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setPageInit(true)
        }
    }

    async function handleUpdate(token, id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateSession(token, id, data);
            const sent_notify = data?.sent_notify;
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    showMessage('success', t('Data updated successfully!'));
                    if(sent_notify){
                        showMessage('success', t('The email has been sent successfully to all users and trainers!'));
                    }
                } else {
                    showMessage('error', response?.data?.message || t('An error occurred, please try again later!'));
                }
            } else {
                showMessage('error', t('An error occurred, please try again later!'));
            }
        } catch (error) {
            showMessage('error', t('An error occurred, please try again later!'));
        } finally {
            setIsLoading(false);
            completeProgress(true);
            location.reload();
        }
    }

    async function handleCancelSession (session_id) {
        try {
            setIsLoadingDelete(true);
            startProgress();
            const response = await deleteUserSession(token, session_id, {
                un_send_email: unSendEmail ? 1 : 0
            });
            if (response && !response.error) {
                handleFindOne();
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try later"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            setDeleteSession(false);
            setIsLoadingDelete(false);
            completeProgress();
            // location.reload();
        }
    };

    async function handleUpdateUserSession (session_id, data) {
        try {
            setIsLoadingDelete(true);
            startProgress();
            const response = await updateUserSessions(token, session_id, data);
            if (response && !response.error) {
                handleFindOne();
            } else {
                showMessage("error", response?.data?.message || t("An error occurred, please try later"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            setIsLoadingDelete(false);
            setSessionUpdate(false);
            setUpdateUser(false);
            completeProgress();
            // location.reload();
        }
    };

    function handleChangeClass(value){
        const { trainers, class_types } = value;
        setClassTypes(JSON.parse(class_types) || []);
        setTrainerOptions(trainers);
        setClass(value)
    }

    function handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const schedule_date = dayjs(data.get('schedule'), "DD/MM/YYYY").format('YYYY-MM-DD');
        
        const start_time = dayjs(data.get('start_time'), 'HH:mm').format("HH:mm:ss");
        const end_time = dayjs(data.get('end_time'), 'HH:mm').format("HH:mm:ss");
        const duration_minutes  = dayjs(data.get('end_time'), 'hh:mm').diff(dayjs(data.get('start_time'), 'hh:mm'), 'minute');

        const notify = data.get('sent_notify');
        const is_free_session = data.get('is_free_session');
        const capacity = data.get('capacity');
        const is_private = data.get('is_private');
        const class_types = data.get('class_types');
        
        const validateForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
            class_types: class_types,
            trainer_id: selectedTrainer,
        };

        const dataForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
            class_types: class_types,
            duration_minutes: duration_minutes,
            is_private: is_private == 'on' ? 1 : 0,
            sent_notify: notify == 'on' ? 1 : 0,
            is_free_session: is_free_session == 'on' ? 1 : 0
        }

        try {
            const error = validate(validateForm, constraints);
            if (!error) {
                handleUpdate(token, id, dataForm);
            } else {
                showMessage("error", t("One or more input fields are invalid!"))
            }
            setFormError(error);
        } catch (error) {

        } 
    }

    async function handleApproveWaitingList(id){
        try {
            setIsLoadingApprove(id);
            const response = await approveWaitingList(token, id, {});
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    showMessage('success', t('Data updated successfully!'));
                    handleFindOne();
                } else {
                    showMessage('error', response?.data?.message || t('An error occurred, please try again later!'));
                }
            } else {
                showMessage('error', t('An error occurred, please try again later!'));
            }
        } catch (error) {
            
        } finally {
            setIsLoadingApprove(false);
            location.reload();
        }
    }

    function handleChangeClassType(e) {
        const form = formRef.current;
        const selectedClassType = classTypes.find((val) => val.title == e.target.value);
        form.elements['capacity'].value = selectedClassType?.capacity;
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || t("must be after start time");
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }


    useEffect(() => {
        if(AcademicClass){
            const { is_private, class_types, capacity } = AcademicClass;
            setClassTypes(class_types ? JSON.parse(class_types) : []);
            setDefaultValue((defaultValue) => ({
                ...defaultValue,
                is_private: is_private,
                capacity: capacity}))
        }
    }, [AcademicClass])

    useEffect(() => {
        if(token){
            handleFindOne();
        }
    }, [token])

    const constraints = {
        trainer_id: {
            presence: { allowEmpty: false }
        },
        academic_class_id: {
            presence: { allowEmpty: false }
        },
        schedule: {
            presence: { allowEmpty: false }
        },
        capacity: {
            presence: { allowEmpty: false }
        },
        start_time: {
            presence: { allowEmpty: false },
        },
        end_time: {
            presence: { allowEmpty: false },
            timeAfter: {
                message: "must be after start time"
            }
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Update Session")}
                    breadcrumb={[
                        {
                            title: t('Sessions'),
                            slug: '/admin/session'
                        },
                        {
                            title: t('Update Session')
                        }
                    ]}
                    endAction={
                       <Stack direction="row" spacing={1}>
                         <Button 
                            sx={{borderRadius: 0}}
                            variant='outlined'
                            onClick={() => navigate(`/admin/sessions/create?schedule=${dayjs(defaultValue?.start_time).format("DD/MM")}`)}>
                            {t("Create another")}
                        </Button>
                        <Button 
                            variant='contained'
                            size='small'
                            onClick={() => navigate(`/admin/sessions/${id}/checkin`)}>
                            {t("Start to checkin")}
                        </Button>
                       </Stack>
                    }
                    backLink={"/admin/sessions?step=" + step}
                />
                <Box ref={formRef} component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <PostPicker
                                label="Class"
                                name="academic_class_id"
                                defaultData={AcademicClass}
                                getApi={findManyClasses}
                                value={AcademicClass}
                                onChange={(value) => handleChangeClass(value)}
                                error={formError?.academic_class_id}
                                optionKey="title"
                                required
                            />
                            {
                                Array.isArray(classTypes) && classTypes.length ? 
                                    <FormControl fullWidth sx={{mb: 3}} required>
                                        <FormLabel>{t("Class Type")}</FormLabel>
                                        <Select
                                            name="class_types"
                                            defaultValue={defaultValue?.class_types}
                                            onChange={(e) => handleChangeClassType(e)}>
                                            {
                                                classTypes.map((val, index) => {
                                                    return (
                                                        <MenuItem key={index} value={val.title}>
                                                            {val.title}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                            : ""
                            }
                            {
                                Array.isArray(trainerOptions) && trainerOptions.length ? 
                                (
                                    <FormControl sx={{ width: "100%", mb: 3 }} required error={formError?.trainer_id}>
                                        <FormLabel component="legend">{t("Trainer")}</FormLabel>
                                        <Select
                                            name="trainer"
                                            value={selectedTrainer}
                                            onChange={(e) => setSelectedTrainer(e.target.value)}
                                            fullWidth>
                                        {
                                            trainerOptions.map((val) => {
                                                return (
                                                    <MenuItem key={val.id} value={val.id}>{val.full_name} {val?.email ? `(${val.email})` : ""}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                ) : ""
                            }
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={t("Is private session")}
                                    checked={defaultValue?.is_private}
                                    onChange={() => setDefaultValue((defaultValue) => ({...defaultValue, is_private: !defaultValue.is_private}))}
                                    name="is_private"
                                />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.schedule} required>
                                <FormLabel>{t("Schedule")}</FormLabel>
                                <DatePicker
                                    name="schedule"
                                    value={dayjs(defaultValue?.start_time, "YYYY-MM-DD HH:mm:ss")}
                                />
                                {formError?.schedule ? <FormHelperText>{formError?.schedule[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                        <FormLabel>{t("Start time")}</FormLabel>
                                        <TimePicker
                                            name="start_time"
                                            value={dayjs(defaultValue?.start_time, "YYYY-MM-DD HH:mm:ss")}
                                        />
                                        {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                        <FormLabel>{t("End time")}</FormLabel>
                                        <TimePicker
                                            name="end_time"
                                            value={dayjs(defaultValue?.end_time, "YYYY-MM-DD HH:mm:ss")}
                                        />
                                        {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                                <FormLabel>{t("Capacity")}</FormLabel>
                                <TextField
                                    name="capacity"
                                    type="number"
                                    defaultValue={defaultValue?.capacity}
                                    error={formError?.capacity}
                                />
                                {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={
                                        <Box>
                                            {t("This session is provided free of charge to registered users")} <Info title={t("Users who do not need a subscription can still register")} />
                                        </Box>
                                    }
                                    checked={defaultValue?.is_free_session}
                                    onChange={() => setDefaultValue((defaultValue) => ({...defaultValue, is_free_session: !defaultValue.is_free_session}))}
                                    name="is_free_session"
                                />
                            </FormControl>
                            {/* <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label={t("Select Users")}
                                    name="user"
                                    defaultData
                                    getApi={findManyUsers}
                                    values={users}
                                    onChange={(value) => {
                                        if (value) {
                                            setUsers((users) => {
                                                let filterUsers = users.filter((_v) => _v?.id !== value.id);
                                                filterUsers = [...filterUsers, value];
                                                return filterUsers;
                                            })
                                        }
                                    }}
                                    optionKey="email"
                                    previewKey="id"
                                />
                            </Box> */}
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={t("Send an email confirmed to notify trainer")}
                                    name="sent_notify"
                                />
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    {t("Update session")}
                                </LoadingButton>
                            </FormControl>
                            <Divider>
                                <h3 className='font-bold mb-3 uppercase mt-4 text-center'>{t("Session booking detailed")}</h3>
                            </Divider>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                                <FormLabel sx={{ mb: 2 }}>{t("Users list")}</FormLabel>
                                <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
                                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                                        <Table
                                            stickyHeader aria-label="sticky table"
                                            size='medium'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                                        <TableCell>{t("Users / Shared users")}</TableCell>
                                                        <TableCell>{t("Membership")}</TableCell>
                                                        <TableCell>{t("Status")}</TableCell>
                                                        <TableCell>{t("Created at")}</TableCell>
                                                        <TableCell>{t("Apply bonus")}</TableCell>
                                                        <TableCell align="right">{t("Actions")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Array.isArray(classSessions) && classSessions.length ?
                                                        classSessions.map((row, index) => {
                                                            const { is_shared, name_shared, user, subscription, status, created_at, id, apply_bonus } = row;
                                                            return (
                                                                <StyledTableRow
                                                                    key={row.id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell component="th" scope="row">
                                                                        {(index + 1)}
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                       <Link to={"/admin/users/" + user?.id}>
                                                                            <p>
                                                                                <strong>{is_shared ? name_shared : user.display_name || user?.email}</strong>
                                                                            </p>
                                                                            <span className="text-xs">{row?.email}</span>
                                                                       </Link>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        {
                                                                            subscription?.membership?.title ? (
                                                                                <Link to={"/admin/memberships/" + subscription?.membership?.id}>
                                                                                    <p>{subscription?.membership?.title}</p>
                                                                                </Link>
                                                                            ) : "-"
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        {
                                                                            status ? (
                                                                                <span className={classNames("border px-1 capitalize text-sm", {
                                                                                    'border-green-200 bg-green-100 text-green-600': status == 'active',
                                                                                    'border-red-200 bg-red-100 text-red-600': status == 'canceled',
                                                                                    'border-yellow-200 bg-yellow-100 text-yellow-600': status == 'pending'
                                                                                })}>
                                                                                    {{
                                                                                        active: t("Booked"),
                                                                                        pending: t("Pending"),
                                                                                        canceled: t("canceled"),
                                                                                    }[status]}
                                                                                </span>
                                                                            ) : "-"
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        <DateCell time={created_at}/>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                    <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                        <FormControlLabel
                                                                            control={<Switch />}
                                                                            checked={apply_bonus}
                                                                            onChange={() => handleUpdateUserSession(id, {
                                                                                apply_bonus: apply_bonus ? 0 : 1
                                                                            })}
                                                                            name="un_send_email"
                                                                        />
                                                                    </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Stack direction="row" spacing="3px">
                                                                            <Button 
                                                                                variant='contained' 
                                                                                size='small' 
                                                                                sx={{ background: "#000" }} 
                                                                                disabled={status == "canceled"}
                                                                                onClick={() => setDeleteSession(id)}>
                                                                                {t("Cancel")}
                                                                            </Button>
                                                                            <Button 
                                                                                variant='outlined' 
                                                                                size='small' 
                                                                                sx={{ borderRadius: 0 }} 
                                                                                onClick={() => setSessionUpdate(id)}>
                                                                                {t("Edit")}
                                                                            </Button>
                                                                        </Stack>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            )
                                                        }) : ""}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <div>
                                    <Button 
                                        variant='outlined' 
                                        onClick={() => setOpenAnotherBook(true)}
                                        sx={{borderRadius: 0}}>
                                        {t("Book for another")}
                                    </Button>
                                </div>
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormLabel sx={{ mb: 2 }}>{t("Waiting list")}</FormLabel>
                                <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
                                    <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                                        <Table
                                            stickyHeader aria-label="sticky table"
                                            size='medium'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ width: 60 }}>{t("No")}</TableCell>
                                                        <TableCell>{t("Users / Shared users")}</TableCell>
                                                        <TableCell>{t("Created at")}</TableCell>
                                                        <TableCell sx={{ width: 120 }}>
                                                            {t("Url")}
                                                        </TableCell>
                                                        <TableCell>
                                                            {t("Approve")}
                                                            <Info title={t("Approve this user to be able to schedule an appointment")}/>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {t("Actions")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        Array.isArray(waitingList) && waitingList.length ?
                                                            waitingList.filter((val) => val?.has_applied != '1').map((row, index) => {
                                                                return (
                                                                    <StyledTableRow
                                                                        key={row.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                        <TableCell component="th" scope="row">
                                                                            {(index + 1)}
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            <Link to={"/admin/users/" + row?.user?.id}>
                                                                                <p>{row?.name_shared ? row?.name_shared : row?.user?.display_name || row?.user?.email}</p>
                                                                                <span className="text-xs">{row?.user?.email}</span>
                                                                            </Link>
                                                                        </TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            <DateCell time={row?.created_at}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                row?.apply_token ? (
                                                                                    <Link className='underline' target='_blank' to={"/apply-waiting-list/" + row?.apply_token}>{t("View")}</Link>
                                                                                ) : "-"
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Stack direction="row" spacing={3}>
                                                                                <LoadingButton 
                                                                                    size='small'
                                                                                    disabled={row?.apply_token}
                                                                                    variant='contained'
                                                                                    sx={{borderRadius: 0}}
                                                                                    loading={isLoadingApprove == row.id} 
                                                                                    onClick={() => handleApproveWaitingList(row.id)}>
                                                                                    {t("Approve")}
                                                                                </LoadingButton>
                                                                            </Stack>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <IconButton onClick={() => setUsers((users) => users.filter((_v, _i) => _v.id !== row.id))}>
                                                                                <DeleteOutlineIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            }) : ""
                                                    }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <BookForSession open={openAnotherBook} setOpen={setOpenAnotherBook} session={defaultValue}/>
            <NotifySession open={notify} setOpen={setNotify} />

            <Modal
                open={deleteSession}
                onClose={() => setDeleteSession(false)}
            >
                <Box sx={{ ...style, maxWidth: 540 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("Do you want to cancel this session?")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("Please note that this action cannot be undone.")}
                    </Typography>
                    <FormControl sx={{ width: "100%", mb: 3 }}>
                        <FormControlLabel
                            control={<Switch />}
                            label={
                                <Box>
                                    {t("Do not notify users by email")}
                                </Box>
                            }
                            checked={unSendEmail}
                            onChange={() => setUnsendEmail(!unSendEmail)}
                            name="un_send_email"
                        />
                    </FormControl>
                    <Stack direction="row" justifyContent="space-between" mt={4}>
                        <Button
                            onClick={() => setDeleteSession(false)}
                            variant='contained'
                            sx={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', textTransform: 'uppercase', borderRadius: 0 }}
                        >
                            {t("Close")}
                        </Button>
                        <LoadingButton
                            onClick={() => handleCancelSession(deleteSession)}
                            loading={isLoadingDelete}
                            variant='contained'
                            sx={{ backgroundColor: '#d32f2f', color: '#fff', padding: '10px 20px', textTransform: 'uppercase', borderRadius: 0 }}
                        >
                            {t("Cancel session")}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={sessionUpdate}
                onClose={() => setSessionUpdate(false)}
            >
                <Box sx={{ ...style, maxWidth: 540 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("Update session user infomation")}
                    </Typography>
                    <PostPicker 
                        getApi={findManyUsers} 
                        values={[updateUser]} 
                        previewKey={'display_name'}
                        optionKey={'email'}
                        onChange={(value) => setUpdateUser(value)}
                    />
                    <Stack direction="row" justifyContent="space-between" mt={4}>
                        <Button
                            onClick={() => setSessionUpdate(false)}
                            variant='contained'
                            sx={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', textTransform: 'uppercase', borderRadius: 0 }}
                        >
                            {t("Close")}
                        </Button>
                        <LoadingButton
                            onClick={() => handleUpdateUserSession(sessionUpdate, {
                                user_id: updateUser?.id
                            })}
                            loading={isLoadingDelete}
                            variant='contained'
                            sx={{ color: '#fff', padding: '10px 20px', textTransform: 'uppercase', borderRadius: 0 }}
                        >
                            {t("Update")}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </Fragment>
    )
}

export default CreateSession
