import { useState, useEffect, Fragment, useMemo } from 'react';
import { deleteCart, findManyCarts } from '@/utils/Cart';
import { numberWithCommas } from '@/utils/Hooks';
import { LoadingButton } from '@mui/lab';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext';
import { Link, useNavigate } from 'react-router-dom';
import { updateCartData } from '@/utils/slice/cartSlice';
import { useTranslation } from 'react-i18next';

import NavHeader from '@/components/NavHeader'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import classNames from 'classnames';
import PageLoading from '@/components/PageLoading';

const index = () => {

    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state.cart);
    const { token } = useSelector((state) => state.auth);
    const [cartTotal, setCartTotal] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [deleteItem, setDeleteItem] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pageInit, setPageInit] = useState(false);

    const navigate = useNavigate();

    const { t } = useTranslation();

    async function handleFindCart() {
        try {
            startProgress();
            const response = await findManyCarts(token);
            if (response && !response.error) {
                const { data } = response;
                dispatch(updateCartData(data));
            } else {
                showMessage("error", t("An error occurred, please try later"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"))
        } finally {
            completeProgress();
            setPageInit("complete");
            setIsLoaded(true);
        }
    }
    async function handleDeleteFromCart(id) {
        try {
            setDeleteItem(id);
            const response = await deleteCart(token, id)
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    await handleFindCart();
                }
            } else {
                showMessage("error", t("An error occurred, please try later"))
            }
        } catch (error) {
            console.error("error: ", error);
            showMessage("error", t("An error occurred, please try later"))
        } finally {
            setDeleteItem(false);
        }
    }

    useEffect(() => {
        if (Array.isArray(cart) && cart?.length) {
            let totalPrice = cart.reduce((acc, value) => acc + (value?.memberships?.regular_price * value?.quantity), 0);
            setCartTotal(totalPrice);
        }
    }, [cart])

    useEffect(() => {
        if(token){
            handleFindCart();
        }
    }, [token])

    if(pageInit != "complete"){
        return <PageLoading />
    }

    return (
        <div>
            {
                Array.isArray(cart) && cart?.length ? (
                    <Fragment>
                        <Box>
                        <NavHeader
                            title={t("Cart")}
                            breadcrumb={[
                                {
                                    title: t('Cart')
                                }
                            ]}
                        />
                        <div className="border border-slate-200 p-2">
                            <List>
                                {
                                    cart.map((val, index) => {
    
                                        const { memberships, quantity } = val; 
                                        const { id, title, description, regular_price } = memberships;

                                        return (
                                            <ListItem
                                                key={id}
                                                className={classNames({'border-b border-dashed border-slate-200': index < (cart.length - 1) })}>
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography sx={{ fontWeight: 600, mb: 1 }}>
                                                        {t(title)}
                                                    </Typography>
                                                    <Typography sx={{ mb: 1, fontSize: 14, whiteSpace: "pre-wrap" }}>
                                                        {t(description)}
                                                    </Typography>
                                                    <Typography>
                                                        {regular_price ? numberWithCommas(regular_price) + "₫" : "-"} x {quantity}
                                                    </Typography>
                                                    <Typography>
                                                        <strong className='mr-2'>{t("Subtotal")}:</strong>
                                                        {numberWithCommas(regular_price * quantity)}₫
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <LoadingButton
                                                        loading={deleteItem == id}
                                                        onClick={() => handleDeleteFromCart(id)}>
                                                        <DeleteOutlineIcon />
                                                    </LoadingButton>
                                                </Box>
                                            </ListItem>
                                        )

                                    })
                                }
                            </List>
                        </div>
                    </Box>
                    
                    <Box className="flex justify-end text-center py-3">
                        <div>
                            <Typography sx={{ mb: 2 }}>
                                <strong className='mr-2'>{t("Total")}:</strong>
                                {numberWithCommas(cartTotal)}₫
                            </Typography>
                            <Button
                                as={Link}
                                to="/checkout"
                                color='secondary'
                                sx={{
                                    padding: "10px 8px",
                                    borderRadius: 0,
                                    textAlign: 'center',
                                    letterSpacing: 2
                                }}
                                variant='contained'>
                                {t("Proceed to Checkout")}
                            </Button>
                        </div>
                    </Box>
                    </Fragment>
                ) : (
                    <Box sx={{ textAlign: "center", py: 3 }}>
                        <AddShoppingCartIcon sx={{ width: 80, height: 80, mb: 1 }} />
                        <Typography sx={{ mb: 3 }}>{t("Cart empty")}</Typography>
                        <Link to="/memberships/">
                            <Button variant='contained' color="secondary">
                                {t("Return to memberships")}
                            </Button>
                        </Link>
                    </Box>
                )
            }

        </div>
    )
}

export default index
