import React, { useEffect, useState, Fragment } from 'react'
import { useToasterContext } from '@/utils/ToasterContext';
import { useSearchParams } from 'react-router-dom';
import PageLoading from '@/components/PageLoading';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { findManyUserSubscriptions } from '@/utils/UserInfomations';
import { useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';

const UserOrders = ({ }) => {

    const { t } = useTranslation(); 
    const { token } = useSelector((state) => state.auth);
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState('idle');

    const { showMessage, startProgress, completeProgress } = useToasterContext()
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    })

    const handleFindManyData = async (token, filter) => {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManyUserSubscriptions(token, filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
            setPageStatus("complete");
        } catch (error) {

        } finally {
            completeProgress();
        }
    }

    useEffect(() => {
        if (token) {
            const initFilter = {
                search: searchParams.get('search'),
                order: searchParams.get('order'),
                start_date: searchParams.get('start_date'),
                end_date: searchParams.get('end_date'),
                paged: searchParams.get('paged'),
                per_page: searchParams.get('per_page'),
            };
            let filterParams = {};
            for (const key in initFilter) {
                if (Object.hasOwnProperty.call(initFilter, key)) {
                    const element = initFilter[key];
                    if (element != null) {
                        filterParams[key] = element;
                    }
                }
            }
            setFilter((filter) => ({
                ...filter,
                ...filterParams
            }));
            handleFindManyData(token, filterParams);
        }
    }, [token])

    useEffect(() => {
        if (pageStatus == 'complete') {
            let filterParams = {};
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    const element = filter[key];
                    if (element) {
                        filterParams[key] = element;
                    }
                }
            }
            handleFindManyData(token, filterParams);
        }
    }, [filter])

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    const headCells = [
        {
            id: 'title',
            label: t('Subscriptions'),
        },
        {
            id: 'class_session_user_count',
            label: t('Remaining sessions'),
        },
        {
            id: 'max_session',
            label: t('Max sessions'),
        },
        {
            id: 'start_date',
            label: t('Start date'),
        },
        {
            id: 'end_date',
            label: t('End date'),
        },
    ];

    const Row = ({ rowData }) => {

        const {
            id,
            class_session_user_count,
            max_sessions,
            start_date,
            end_date,
            membership,
        } = rowData;
        const [open, setOpen] = useState(false);
        const isItemSelected = isSelected(id);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <p onClick={() => setOpen(!open)}>
                            {membership?.title}
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            {max_sessions == -1 ? t("Unlimited") : class_session_user_count}
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            {max_sessions == -1 ? t("Unlimited") : max_sessions}
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            {start_date ? dayjs(start_date, "YYYY-MM-DD").format("DD/MM/YYYY"): "-"}
                        </p>
                    </TableCell>
                    <TableCell>
                        <p>
                            {end_date ? dayjs(end_date, "YYYY-MM-DD").format("DD/MM/YYYY"): "-"}
                        </p>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    function EnhancedTableHead(props) {
        const { order, orderBy } = props;
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <p className='font-bold'>
                                {headCell.label}
                            </p>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={data?.length}
                        />
                        <TableBody>
                            {data.map((row) => {
                                return (
                                    <Row key={row.id} rowData={row} />
                                );
                            })}
                        </TableBody>
                    </Table>
                    {
                        selected.length ? (
                            <EnhancedTableToolbar
                                numSelected={selected.length}
                                isLoading={deleteLoading}
                                onSubmit={() => handleDeleteMultipeData(selected)}
                            />
                        ) : ""
                    }
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[12, 24, 36]}
                    component="div"
                    count={total || 0}
                    rowsPerPage={parseInt(filter?.per_page) || 12}
                    page={parseInt(filter?.paged - 1)}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export default UserOrders
