import React, { useState } from 'react'
import PostPicker from './PostPicker';
import { Select, MenuItem, FormControl, FormLabel, Box  } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { findManyMemberships } from '@/utils/Memberships';
import { findManyUsers } from '@/utils/Users';

const FilterSubscription = ({ filter, setFilter }) => {

  const { t } = useTranslation();
  
  const [membership, setMembership] = useState(false);
  const [user, setUser] = useState(false);
  const [open, setOpen] = useState(false);

  function handleChangeMemberShip(value){
    setMembership(value);
    setFilter((filter) => ({...filter, membership_id: value?.id}))
  }

  function handleChangeUser(value){
    setUser(value);
    setFilter((filter) => ({...filter, user_id: value?.id}))
  }

  return (
    <Box>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-3 mb-5'>
           <PostPicker 
              label={t("User")}
              getApi={findManyUsers}
              values={[user]}
              optionKey={"email"}
              previewKey={"display_name"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeUser(value)}
            />
            <PostPicker 
              label={t("Membership")}
              getApi={findManyMemberships}
              values={[membership]}
              optionKey={"title"}
              labelStyle={{mb: 1, fontSize: 14}}
              onChange={(value) => handleChangeMemberShip(value)}
            />
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("Validate")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.validate}
                  onChange={(e) => setFilter((filter) => ({
                      ...filter, 
                      validate: e.target.value,
                      paged: 1
                  }))}
                  size='small'>
                  <MenuItem value="sessions_remaining">{t("3 sessions remaining")}</MenuItem>
                  <MenuItem value="days_remaining">{t("3 days left")}</MenuItem>
                  <MenuItem value="invalid">{t("Invalid subscription")}</MenuItem>
                  <MenuItem value="all">{t("All")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
              <FormLabel sx={{mb: 1, fontSize: 14}}>{t("Status")}</FormLabel>
              <Select 
                  fullWidth
                  value={filter?.status}
                  onChange={(e) => setFilter((filter) => ({
                    ...filter, 
                    status: e.target.value,
                    paged: 1
                  }))}
                  size='small'>
                  <MenuItem value="active">{t("Active")}</MenuItem>
                  <MenuItem value="freeze">{t("Freeze")}</MenuItem>
                  <MenuItem value="inactive">{t("Inactive")}</MenuItem>
                  <MenuItem value="all">{t("All")}</MenuItem>
                </Select>
            </FormControl>
        </div>
    </Box>
  )
}

export default FilterSubscription