import { store } from './utils/store'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client';
import App from './App';
import "./index.css";

const container = document.getElementById('root');
const root = createRoot(container);

const Document = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
};

root.render(<Document />);
