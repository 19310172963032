import { Box, Typography, Button, Divider, Chip, Modal, Stack } from '@mui/material';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useToasterContext } from '@/utils/ToasterContext';
import { deleteUserSession, findManyUserSubscriptions, findOneUserSession } from '@/utils/UserInfomations';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import QRCode from "react-qr-code";
import PageLoading from '@/components/PageLoading';
import NavHeader from '@/components/NavHeader';

import isBetween from 'dayjs/plugin/isBetween';
// Kích hoạt plugin
dayjs.extend(isBetween);

dayjs.extend(customParseFormat);

const APP_URL = process.env.REACT_APP_APP_URL;

const UserSessionInformation = () => {

    const { user, token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { t } = useTranslation();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const navigate = useNavigate();

    // Gộp trạng thái thành một object để dễ quản lý hơn
    const [state, setState] = useState({
        isLoading: false,
        pageInit: false,
        defaultValue: null,
        trainer: [],
        AcademicClass: [],
        users: [],
        subscriptions: [],
        selectedClassType: null,
        classSessions: [],
        selectedClassSession: null,
        deleteSession: false,
    });

    const { 
        isLoading, 
        pageInit, 
        defaultValue, 
        trainer, 
        AcademicClass, 
        users, 
        selectedClassType, 
        classSessions, 
        selectedClassSession, 
        deleteSession 
    } = state;

    const handleClose = () => {
        setState(prevState => ({ ...prevState, deleteSession: false }));
    };

    const handleCancelSession = useCallback(async (session_id) => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));
            const response = await deleteUserSession(token, session_id);
            if (response && !response.error) {
                handleClose();
                handleFindOneSession();
            } else {
                showMessage("error", t("An error occurred, please try later"));
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"));
        } finally {
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    }, [token, navigate, showMessage]);

    const handleFindManyUserSubscriptions = useCallback(async (token) => {
        try {
            const response = await findManyUserSubscriptions(token, {});
            if (response && !response.error) {
                const { data } = response;
                setState(prevState => ({ ...prevState, subscriptions: Array.isArray(data) ? data : [] }));
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleFindOneSession = useCallback(async () => {
        try {
            startProgress();
            const response = await findOneUserSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const { class__session, trainer, users } = data;
                    const academicClass = data?.class;
                    const currentSession = class__session?.find(val => val?.is_shared == 0);
                    setState(prevState => ({
                        ...prevState,
                        defaultValue: {
                            title: data.title,
                            class_types: data.class_types,
                            capacity: data.capacity,
                            start_time: data.start_time,
                            end_time: data.end_time,
                        },
                        trainer: trainer,
                        users: users,
                        AcademicClass: academicClass,
                        classSessions: class__session || [],
                        selectedClassSession: currentSession,
                    }));
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            completeProgress();
            setState(prevState => ({ ...prevState, pageInit: "complete" }));
        }
    }, [token, id, startProgress, completeProgress]);

    useEffect(() => {
        if (token) {
            handleFindManyUserSubscriptions(token);
            handleFindOneSession();
        }
    }, [token, handleFindManyUserSubscriptions, handleFindOneSession]);

    // Tính toán giá trị duration chỉ khi cần
    const durationInMinutes = useMemo(() => {
        if (!defaultValue?.start_time || !defaultValue?.end_time) return 0;
        const start = dayjs(defaultValue.start_time);
        const end = dayjs(defaultValue.end_time);
        return end.diff(start, 'minute');
    }, [defaultValue]);

    // Tính toán so sánh thời gian session với thời gian hiện tại
    const sessionDateCompare = useMemo(() => {
        return dayjs().isAfter(dayjs(defaultValue?.start_time));
    }, [defaultValue]);

    const isInBetween = dayjs().isBetween(defaultValue?.start_time, defaultValue?.end_time);

    // Cập nhật selected class type khi `defaultValue` hoặc `AcademicClass` thay đổi
    useEffect(() => {
        const classTypes = AcademicClass?.class_types ? JSON.parse(AcademicClass.class_types) : null;
        const selectedClass = classTypes?.find(val => val?.title === defaultValue?.class_types);
        setState(prevState => ({ ...prevState, selectedClassType: selectedClass }));
    }, [defaultValue, AcademicClass]);

    function isMoreThan12Hours(start_time) {
        // Lấy thời gian hiện tại
        const now = dayjs();
        
        // Tạo đối tượng dayjs từ start_time
        const targetTime = dayjs(start_time);
        
        // Tính khoảng thời gian giữa hiện tại và start_time tính theo giờ
        const diffInHours = targetTime.diff(now, 'hour');
        
        // Trả về true nếu khoảng cách lớn hơn 1 giờ, ngược lại false

        return diffInHours > 32;
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const SharedPersonal = ({ data }) => {
        const [viewQr, setViewQr] = useState(false);
        const { id, name_shared, check_in_token, status } = data;

        return (
            <Box className='p-3 border border-black mb-5'>
                <Stack 
                    direction="row" 
                    sx={{justifyContent: "space-between", alignItems: "center"}} 
                    spacing={1}>
                    <div className='flex-1'>
                        <Typography><strong>{name_shared}</strong></Typography>
                    </div>
                    <span className='border border-black px-1 text-[12px] capitalize'>{status}</span>
                </Stack>
                <Box sx={{ background: "#fff", mt: 3}}>
                    <QRCode
                        size={256}
                        style={{
                            height: "auto",
                            maxWidth: 180,
                            margin: "auto",
                            width: "100%",
                            marginBottom: 15,
                        }}
                        value={APP_URL + `/session-checkin?token=${check_in_token}`}
                        viewBox={`0 0 256 256`}
                    />  
                    <Typography sx={{ textAlign: "center", textTransform: "uppercase" }}>
                        {t("Scan to check-in")}
                    </Typography>       
                    {
                    !sessionDateCompare ? (
                            <div className='text-center mt-5'>
                                {
                                    status != 'canceled' ? 
                                        <Button
                                            variant='contained'
                                            onClick={() => setState(prevState => ({ ...prevState, deleteSession: id }))}
                                            sx={{
                                                mb: 3,
                                                background: "#000",
                                                borderRadius: 0,
                                                fontWeight: 600
                                            }}>
                                            {t("Cancel session")}
                                        </Button> : 
                                        <Typography className="font-normal tracking-[2px] uppercase">
                                            {t("Session canceled")}
                                        </Typography>
                                }
                            </div>
                        ): <Typography className="font-normal tracking-[2px] uppercase">
                            { isInBetween ? t("Session is ongoing") : t("Session expired")}
                        </Typography>
                }
                </Box>
            </Box>
        )
    } 

    if (pageInit !== 'complete') {
        return <PageLoading />;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title={t("Your session")}
                breadcrumb={[{ title: t('Your session') }]}
                backLink="/account/"
            />
            <div className="max-w-[520px] mx-auto">
                <Box>
                    <Divider sx={{ mb: 2 }}>
                        <Chip label={t("Session information")} className='uppercase' sx={{ borderRadius: 0 }} />
                    </Divider>
                    <Box sx={{ mb: 3 }}>
                        {selectedClassType || AcademicClass ? (
                            <Box sx={{mb: 2}}>
                                <Typography>
                                    <span className='text-2xl font-bold uppercase'>
                                        {t((selectedClassType || AcademicClass)?.title)}
                                    </span>
                                </Typography>
                                <Typography className="text-sm">
                                    {t((selectedClassType || AcademicClass)?.description)}
                                </Typography>
                            </Box>
                        ) : null}
                        <Typography>
                            {t("on")} <span className='font-bold uppercase'> {dayjs(defaultValue?.start_time).format('ddd, DD/MM')}</span>
                        </Typography>
                        <Typography>
                            {t("at")} <span className='font-bold uppercase'>{dayjs(defaultValue?.start_time).format('HH:mm')}</span>
                        </Typography>
                        <Typography>
                            {t("Duration")}: <span className="font-bold">{durationInMinutes} minutes</span>
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            {t("Instructor")}: <strong>{trainer?.full_name}</strong> 
                        </Typography>
                        {selectedClassSession?.check_in_token && (
                            <Box className='p-3 border border-black mb-5'>
                                <Stack 
                                    direction="row" 
                                    sx={{justifyContent: "space-between", alignItems: "center"}} 
                                    spacing={1}>
                                    <div className='flex-1'>
                                        <Typography><strong>{user?.display_name}</strong></Typography>
                                    </div>
                                    <span className='border border-black px-1 text-[12px] capitalize'>{selectedClassSession?.status}</span>
                                </Stack>
                                <Box sx={{ background: "#fff", mt: 3}}>
                                    <QRCode
                                        size={256}
                                        style={{
                                            height: "auto",
                                            maxWidth: 180,
                                            margin: "auto",
                                            width: "100%",
                                            marginBottom: 15,
                                        }}
                                        value={APP_URL + `/session-checkin?token=${selectedClassSession?.check_in_token}`}
                                        viewBox={`0 0 256 256`}
                                    />  
                                    <Typography sx={{ textAlign: "center", textTransform: "uppercase" }}>
                                        {t("Scan to check-in")}
                                    </Typography>       
                                    {
                                        !sessionDateCompare ? (
                                            <div className='text-center mt-5'>
                                                {
                                                    selectedClassSession?.status != 'canceled' ? 
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => setState(prevState => ({ ...prevState, deleteSession: selectedClassSession?.id }))}
                                                            sx={{
                                                                mb: 3,
                                                                background: "#000",
                                                                borderRadius: 0,
                                                                fontWeight: 600
                                                            }}>
                                                            {t("Cancel session")}
                                                        </Button> : 
                                                        <Typography className="font-normal tracking-[2px] uppercase">
                                                            {t("Session canceled")}
                                                        </Typography>
                                                }
                                            </div>
                                        ): (
                                            <Typography className="font-normal tracking-[2px] uppercase">
                                                    { isInBetween ? t("Session is ongoing") : t("Session expired")}
                                            </Typography>
                                        )
                                }
                                </Box>
                            </Box>
                        )}
                        {Array.isArray(classSessions) && classSessions.filter(val => val?.is_shared === 1).length > 0 && (
                            <Box sx={{ mt: 2 }}>
                                <h3 className='font-bold mb-0'>{t("Shared with person")}</h3>
                                {classSessions.filter(val => val?.is_shared === 1).map((val, index) => (
                                    <SharedPersonal data={val} key={index} />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>
            <Modal
                open={!!deleteSession}
                onClose={handleClose}
            >
                <Box sx={{ ...style, maxWidth: 540 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("Do you want to cancel this session?")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {t("Please note that this action cannot be undone.")}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" mt={4}>
                        <Button
                            onClick={handleClose}
                            variant='contained'
                            sx={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', textTransform: 'uppercase', borderRadius: 0 }}
                        >
                            {t("Close")}
                        </Button>
                        <LoadingButton
                            onClick={() => handleCancelSession(deleteSession)}
                            loading={isLoading}
                            variant='contained'
                            sx={{ backgroundColor: '#d32f2f', color: '#fff', padding: '10px 20px', textTransform: 'uppercase', borderRadius: 0 }}
                        >
                            {t("Cancel session")}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
};
export default UserSessionInformation;