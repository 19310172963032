import { Fragment } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { 
    IoHomeOutline, 
    IoImagesOutline, 
    IoCalendarOutline, 
    IoFolderOpenOutline, 
    IoCubeOutline, 
    IoGitMergeOutline, 
    IoPersonOutline, 
    IoCartOutline, 
    IoPeopleOutline, 
    IoStarOutline,
    IoPricetagsOutline, 
    IoLanguageOutline
} from "react-icons/io5";

import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ListItems = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    return (
        <Fragment>
            <Link to="/admin">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/admin"})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoHomeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Dashboard")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/media">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/media")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoImagesOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Media")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/trainers">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/trainers")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoPersonOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Instructors")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/classes">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/classes")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoFolderOpenOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Classes")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/memberships">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/memberships")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoCubeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Memberships")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/integrations">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/integrations")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoGitMergeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Integrations")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/sessions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/sessions")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoCalendarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Sessions")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/promotions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/promotions")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoPricetagsOutline  size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Promotions")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/orders">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/orders")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoCartOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Orders")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/subscriptions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/subscriptions")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoStarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Subscriptions")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/users">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/users")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoPeopleOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Users")} />
                </ListItemButton>
            </Link>
            <Link to="/admin/translations">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/translations")})}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <IoLanguageOutline  size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary={t("Translations")} />
                </ListItemButton>
            </Link>
        </Fragment>
    )
};

export default ListItems;
