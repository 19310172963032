import { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ConfirmDelete from '@/components/ConfirmDelete';
import ConfirmRefund from '@/components/ConfirmRefund';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { deleteTransaction, refundTransaction } from '@/utils/Transactions';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext';


const headCells = [
    {
        id: 'transaction_ref',
        numeric: false,
        disablePadding: false,
        label: 'Ref',
    },
    {
        id: 'transaction_type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'transaction_status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'created_at',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function TableTransaction({ defaultData }) {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);
    const { token } = useSelector((state) => state.auth);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [refundItem, setRefundItem] = useState(false);
    const [refundLoading, setRefundLoading] = useState(false);
    const { showMessage } = useToasterContext();
  
    const handleChangeLimit = dataKey => {
      setPage(1);
      setLimit(dataKey);
    };

    const data = defaultData.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });

    async function handleRefund(id) {
        try {
            setRefundLoading(true);
            const response = await refundTransaction(token, id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    handleFindManyData(filter);
                    showMessage("success", "Delete item successully!")
                }
            } else {
                showMessage("error", "An error occurred during delete item!")
            }
        } catch (error) {
            showMessage("error", "An error occurred during delete item!")
        } finally {
            setRefundLoading(false);
            setRefundItem(false);
        }
    }

    async function handleDeleteData(id) {
        try {
            setDeleteLoading(true);
            const response = await deleteTransaction(token, id);
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    showMessage("success", "Delete item successully!");
                }
            } else {
                showMessage("error", "An error occurred during delete item!")
            }
        } catch (error) {
            showMessage("error", "An error occurred during delete item!")
        } finally {
            setDeleteLoading(false);
            setDeleteItem(false);
        }
    }

    const ActionCell = ({ rowData, editUrl, setDeleteItem }) => {
        console.log('rowData', rowData);
        return (
            <PopupState variant="popover" popupId="edit-popup-menu">
                {(popupState) => (
                    <Fragment>
                        <IconButton variant="outlined" {...bindTrigger(popupState)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                            {
                               rowData?.transaction_status == 'success' ? (
                                <MenuItem onClick={() => setRefundItem(rowData?.id)}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Refund</ListItemText>
                                </MenuItem>
                               ) : ""
                            }
                            <MenuItem onClick={() => setDeleteItem(rowData?.id)}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Fragment>
                )}
            </PopupState>
        )
    }

    const Row = ({ rowData }) => {

        const {
            transaction_ref,
            transaction_type,
            transaction_status,
            transaction_amount,
            created_at
        } = rowData;
        const [open, setOpen] = useState(false);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={transaction_ref}
                    onClick={() => setOpen(!open)}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center">    
                            <Typography>
                                {transaction_ref}
                            </Typography>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {transaction_type}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {transaction_amount}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {transaction_status}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {created_at ? dayjs(created_at).format('hh:mm:ss A, DD/MM/YYYY') : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ width: 120}}>
                        <ActionCell
                            rowData={rowData}
                            editUrl="/admin/orders/"
                            setDeleteItem={setDeleteItem}
                        />
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', overflow: 'hidden', mb: 3, borderRadius: 0 }}>
                <TableContainer sx={{ maxHeight: 640, maxWidth: "calc(100vw - 54px)" }}>
                    <Table
                        stickyHeader aria-label="sticky table"
                        size='medium'>
                            <EnhancedTableHead
                                rowCount={data?.length}
                            />
                            <TableBody>
                                {data.map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[6, 12, 24]}
                        component="div"
                        count={defaultData?.length || 0}
                        rowsPerPage={limit}
                        page={page - 1}
                        onRowsPerPageChange={(e) => handleChangeLimit(e.target.value)}
                        onPageChange={(e, newPage) => setPage(newPage ? (newPage + 1) : 1)}
                    />
                </Paper>
                <ConfirmDelete
                    open={deleteItem ? true : false}
                    setOpen={setDeleteItem}
                    isLoading={deleteLoading}
                    onSubmit={() => handleDeleteData(deleteItem)}
                />
                <ConfirmRefund
                    open={refundItem ? true : false}
                    setOpen={setRefundItem}
                    isLoading={refundLoading}
                    onSubmit={() => handleRefund(refundItem)}
                />
        </Box>
    );
}