import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToasterProvider } from './utils/ToasterContext';
import { routes } from './Routes';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'dayjs/locale/en-gb';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#2c3e50',
            contrastText: '#fff',
        },
        secondary: {
            main: '#000000',
            contrastText: '#fff',
        },
        success: {
            main: '#27ae60',
            contrastText: '#fff',
        },
        info: {
            main: '#2980b9',
            contrastText: '#000',
        },
        orange: {
            main: '#f39c12',
            contrastText: '#000',
        },
        warning: {
            main: '#f1c40f',
            contrastText: '#000',
        },
        error: {
            main: '#d35400',
            contrastText: '#000',
        },
        grey: {
            main: '#7f8c8d',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: 'Khmer MN',
        fontSize: 15,
    },
    components: {
        MuiCssBaseline: {
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: "small",
              }
        },
        MuiSelect: {
            defaultProps: {
              size: "small",
            }
        },
        MuiTextField: {
            defaultProps: {
              size: "small"
            }
        }
    }
});

const router = createBrowserRouter(routes);

const App = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <ToasterProvider>
                    <RouterProvider router={router} />
                </ToasterProvider>
            </LocalizationProvider>;
        </ThemeProvider>
    )
}

export default App;
